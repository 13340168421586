import {Backdrop, Button, Card, CardHeader, Modal} from "@mui/material";
import React, {useEffect, useState} from "react";
import styles from './Branding.module.css';
import {Add, Delete, Edit} from "@mui/icons-material";
import CustomPageForm from "./custompages/CustomPageForm";
import LoadingIndicator from "../../components/LoadingIndicator";
import useToken from "../../hooks/useToken";
import {ICustomPage} from "../../api/adminApiClient";
import useAdminApiClient from "../../data/adminApiSource";
import generalApiSource from "../../data/generalApiSource";
import {ApiClientResult, IKovaCommunity} from "../../api/generalApiClient";
import useGeneralApiClient from "../../data/generalApiSource";

export default function ModifyCustomPages() {
  const {adminToken} = useToken();
  const adminApiSource = useAdminApiClient();
  const [isLoadingIndicatorActive, setIsLoadingIndicatorActive] = useState(true);
  const [pages, setPages] = useState<ICustomPage[]>();
  const [communities, setCommunities] = useState<IKovaCommunity[] | undefined>(undefined);
  const [selectedPage, setSelectedPage] = useState<ICustomPage>();
  const [openModal, setOpenModal] = useState<boolean>(false);

  const generalApiSource = useGeneralApiClient();

  function handleAddClick() {
    try {
      setSelectedPage(undefined);
      setOpenModal(true);
    } catch (err) {
      alert('Add failed!');
    }
  }

  function handleEditClick(id:string) {
    try {
      setSelectedPage(pages?.find(page => page.id === id));
      setOpenModal(true);
    } catch (err) {
      alert('Edit failed!');
    }
  }

  function handleDeleteClick(id:string) {
    try {
      setIsLoadingIndicatorActive(true);
      adminApiSource.deleteCustomPage(adminToken!, id).then(pages => {
        loadPages()
      });
    } catch (err) {
      alert('Edit failed!');
    }
  }

  function loadPages() {
    try {
      setIsLoadingIndicatorActive(true);
      adminApiSource.fetchCustomPages(adminToken!).then(pages => {
        setPages(pages);
        setIsLoadingIndicatorActive(false);
      });
    } catch (err) {
      alert('Edit failed!');
    }
  }

  function loadCommunities() {
    setIsLoadingIndicatorActive(true);
    generalApiSource.getCommunities(adminToken!).then(communities => {
      //sort and add not selected
      let sortedCommunites = [...communities.result].sort((c1, c2) => c1.Name > c2.Name ? 1 : -1);
      let notSelectedCommunity = {CommunityID: "", CommunityRID : 0, Name: "Not Selected"} as IKovaCommunity;
      sortedCommunites.unshift(notSelectedCommunity);

      setCommunities(sortedCommunites);
      setIsLoadingIndicatorActive(false);
    }).catch(error => {
      alert('Loading communities failed');
    });
  }


  useEffect(() => {
    try {
      loadPages();
      loadCommunities();
    } catch (err) {
      alert('Could not get files!');
    }
  }, []);


  return (
    <>
      <Card>
        <CardHeader title={"Custom Pages"} />
        <div className={styles.customPagesTable}>
          <header>
            <div>Page Name</div>
            <div>URL</div>
            <div>Community</div>
            <div>Status</div>
          </header>
          {
            (!pages || !communities)? (<div>Loading Pages...</div>) :
            pages.length > 0 ?
              pages.map( page => {
                let c = communities.find(c => c.CommunityRID == page.communityRID);
                let communityName = c ? c.Name : "N/A";

                return (
                  <div className={styles.linkItem} key={page.id}>
                    <div>{page.text}</div>
                    <div>{page.url}</div>
                    <div>{communityName}</div>
                    <div style={{ color: page.enabled ? 'green' : 'red' }}>{page.enabled ? 'enabled' : 'disabled'}</div>
                    <button
                      onClick={() => handleEditClick(page.id!)}
                      style={{
                        background: 'none',
                        border: 'none'
                      }}
                    >
                      <Edit />
                    </button>
                    <button
                      onClick={() => handleDeleteClick(page.id!)}
                      style={{
                        background: 'none',
                        border: 'none'
                      }}
                    >
                      <Delete />
                    </button>
                  </div>
                )
              })
              : (
                <div>No Custom Pages Found.</div>
              )
          }
        </div>

        <Button
          onClick={() => handleAddClick()}
          startIcon={<Add />}
          sx={{ bgcolor: 'primary.light' }}
          variant="contained"
        >
          ADD
        </Button>
      </Card>
      <Modal
        BackdropProps={{ sx: { position: 'absolute' }}}
        container={() => document.getElementById('main')}
        open={openModal}
        sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}
      >
        <CustomPageForm onClose={() => setOpenModal(false)} onSave={() => {setOpenModal(false);loadPages()}} selectedPage={selectedPage} communities={communities} />
      </Modal>
      {isLoadingIndicatorActive && (
        <Backdrop
          open={isLoadingIndicatorActive}
          sx={{ bgcolor: 'rgba(0, 0, 0, 0.6)', color: '#fff', position: 'absolute' }}
        >
          <LoadingIndicator
            color="#fff"
            isLoading={isLoadingIndicatorActive}
            message='Loading pages...'
          />
        </Backdrop>
      )}
    </>
  );
}
