import {Alert, Box, Card, CardContent, CardHeader, Fab, Snackbar, Tooltip} from "@mui/material";
import React, {useEffect, useState} from "react";
import {
    GridRowsProp,
    GridRowModesModel,
    GridRowModes,
    DataGrid,
    GridColDef,
    GridToolbarContainer,
    GridActionsCellItem,
    GridEventListener,
    GridRowId,
    GridRowModel,
    GridRowEditStopReasons,
    GridActionsColDef,
    GridValidRowModel,
    GridRowParams,
    GridEditCellProps,
    GridEditInputCell,
    GridRenderEditCellParams, GridEditSingleSelectCell
} from "@mui/x-data-grid";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import useToken from "../../hooks/useToken";
import useAdminApiClient from "../../data/adminApiSource";
import {CustomViewConfiguration} from "../../api/adminApiClient";
import {CustomViewConfigurationsToolbar} from "./CustomViewConfigurationsToolbar";
import {CustomViewConfigurationGridColumns} from "./CustomViewConfigurationGridColumns";

export interface CustomViewConfigurationProps {
    errorHandler: (error:Error) => void;
    typeOfCustomViews: string;
}

export interface CustomViewConfigurationRow extends CustomViewConfiguration {
    isNew: boolean;
}

export function CustomViewConfigurations(props: CustomViewConfigurationProps) {
    const { errorHandler, typeOfCustomViews } = props;

    // Data of the rows
    const [rows, setRows] = useState<CustomViewConfigurationRow[]>([]);
    // States of the rows
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    // Custom views available to select for Custom View name field
    const [availableCustomViews, setAvailableCustomViews] = useState<string[]>([]);
    // Notification messages
    const [snackbarMessage, setSnackbarMessage] = useState<string>();

    const apiClient = useAdminApiClient();
    const {adminToken} = useToken();

    // Populate existing Custom View Configurations and fetch Kova Custom Views that can be configured.
    useEffect(() => {
        apiClient.getCustomViewConfigurations(typeOfCustomViews)
            .then(result => {
                setRows(
                    result.map((config) => { return { ...config, isNew: false}}));
            })
            .catch(error => errorHandler(error));

            apiClient.getKovaCustomViews().then(result => {
                setAvailableCustomViews(result.map(view => view.name))
            })
            .catch(error => errorHandler(error));
    }, [])

//#region "Grid button event handlers"
    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: GridRowId) => () => {

        if (id) {
            apiClient.deleteCustomViewConfiguration(adminToken!, id.toString())
                .then(result => {
                    setSnackbarMessage("Configuration deleted");
                })
                .catch(error => errorHandler(error))
        }

        setRows(rows.filter((row) => row.id !== id));
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow!.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };
//#endregion "Grid button event handlers"

    // Handle edit and create
    const processRowUpdate = (configRow: GridRowModel, oldRow: GridRowModel) => {

        if (!configRow.name || !configRow.header) {
            return oldRow;
        }

        // Create
        if (configRow.isNew) {
            apiClient.createCustomViewConfiguration(adminToken!, {...configRow, type: typeOfCustomViews})
                .then(newId => {
                    setSnackbarMessage("New configuration saved");
                    const updatedRow = {...configRow, id: newId, isNew: false};
                    replaceRow(configRow.id, updatedRow);
                    return updatedRow;
                })
                .catch(error => errorHandler(error))
        }
        // Edit
        else {
            apiClient.updateCustomViewConfiguration(adminToken!, configRow)
                .then(result => {
                    setSnackbarMessage("Configuration updated");

                    const updatedRow = {...configRow, isNew: false};
                    replaceRow(configRow.id, updatedRow);
                    return updatedRow;
                })
                .catch(error => errorHandler(error))
        }

        return configRow;
    }

    // Replace row with id
    const replaceRow = (id: string, newRow: GridValidRowModel) => {
        // @ts-ignore
        setRows(rows.map((row) => { return (row.id === id ? newRow : row)}));
    }

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    function closeSnackbar() {
        setSnackbarMessage(undefined);
    }


    return <Card sx={{width: '100%', height: '100%'}}>
        <CardHeader title={typeOfCustomViews + " - Custom Views Configuration"} />
        <CardContent>
            <Box
                sx={{
                    width: "100%",
                    "& .actions": {
                        color: "text.secondary"
                    },
                    "& .textPrimary": {
                        color: "text.primary"
                    }
                }}
            >

                <DataGrid
                    rows={rows}
                    columns={CustomViewConfigurationGridColumns({availableCustomViews, rowModesModel, handleSaveClick, handleCancelClick, handleEditClick, handleDeleteClick})}
                    editMode="row"
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    slots={{
                        toolbar: CustomViewConfigurationsToolbar,
                    }}
                    slotProps={{
                        toolbar: { setRows, setRowModesModel, rows },
                    }}
                    autoHeight={true}
                />

                {snackbarMessage && (
                    <Snackbar
                        open
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        onClose={closeSnackbar}
                        autoHideDuration={4000}
                    >
                        <Alert onClose={closeSnackbar}>{snackbarMessage}</Alert>
                    </Snackbar>
                )}
            </Box>
        </CardContent>
    </Card>
}