import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import {ArrowBack} from "@mui/icons-material";
import {Fab} from "@mui/material";
import {Link, useLocation} from "react-router-dom";

interface HeaderProps {
  onDrawerToggle: () => void;
  onLogout: () => void;
}

export default function Header(props: HeaderProps) {
  const location = useLocation();
  const { onDrawerToggle, onLogout } = props;

  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={1} >
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Grid sx={{ display: { sm: 'none', xs: 'block' } }} item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={onDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            { location.pathname && location.pathname !== "/" && (
              <Grid item>
                <Link to={location.pathname.substring(0, location.pathname.lastIndexOf('/'))}>
                  <Fab size={"small"} aria-label="back" sx={{
                    backgroundColor:"primary.light",
                    ":hover":{
                        backgroundColor:"primary.dark",
                    }
                  }}
                  >
                    <ArrowBack />
                  </Fab>
                </Link>
              </Grid>
            )}
            <Grid item xs />
            <Grid item>
            </Grid>
            <Grid item>
              <Button
                onClick={() => onLogout()}
                color="inherit"
                variant="outlined"
              >
                LOGOUT
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
