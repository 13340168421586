import {GridRowModes, GridRowModesModel, GridRowsProp, GridToolbarContainer} from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import React from "react";
import {CustomViewConfigurationRow} from "./CustomViewConfigurations";

interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
        newModel: (oldModel: GridRowModesModel) => GridRowModesModel
    ) => void;
    rows: CustomViewConfigurationRow[]
}

// Toolbar for the grid that handles Custom View configurations. Contains button to create new configurations.
export function CustomViewConfigurationsToolbar(props: EditToolbarProps) {
    const { setRows, setRowModesModel, rows } = props;

    const handleClick = () => {
        // If new row hasn't been opened yet, open one.
        if (!rows.find((row) => row.id === "")) {
            setRows((oldRows) => [...oldRows, {
                id: "",
                name: "",
                type: "",
                header: "",
                sortOrder: 0,
                show: false,
                isNew: true
            }]);
            setRowModesModel((oldModel) => ({
                ...oldModel,
                [""]: {mode: GridRowModes.Edit, fieldToFocus: "name"}
            }));
        }
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                NEW CONFIGURATION
            </Button>
        </GridToolbarContainer>
    );
}