import React, {useState} from "react";
import styles from './ColorSchemeForm.module.css';
import {colorSchemeMetadata} from "./metadata";
import {Button} from "@mui/material";
import {Update} from "@mui/icons-material";
import {IColorScheme} from "../../../api/adminApiClient";

export function ColorSchemeForm(props: { defaultColorScheme?: IColorScheme, colorScheme: IColorScheme, onEditCommunity:(colorScheme: IColorScheme)=>void}) {
  const {onEditCommunity, defaultColorScheme, colorScheme} = props;
  const [editedColorScheme, setEditedColorScheme] = useState<IColorScheme>({} as IColorScheme);

  function updateColorSchemes() {
    const updatedColorScheme = {...colorScheme, ...editedColorScheme};

    onEditCommunity(updatedColorScheme);
  }

  if (colorScheme.communityRID !== editedColorScheme.communityRID)
  {
    setEditedColorScheme(colorScheme);
  }
  //let fieldIdToMetadata = Object.assign({}, ...colorSchemeMetadata.map((x) => ({[x.fieldId]: x})));

  return (
    <div className={styles.colorSchemeForm} key={editedColorScheme.id}>
      <div className={styles.tableHeader}>
        <div className={styles.tableChild}> Name  </div>
        <div className={styles.tableChildText}> Inherited  </div>
        <div className={styles.tableChildEdit}> Current  </div>
        <div className={styles.tableChild}> Info  </div>
      </div>
      {
        editedColorScheme && colorSchemeMetadata.map(metadata => {

          const fieldId = metadata.fieldId;
          const defaultColor = defaultColorScheme ? defaultColorScheme[fieldId as keyof IColorScheme] : '';
          let currentColor = editedColorScheme[fieldId as keyof IColorScheme];
          if (!currentColor) {
            currentColor = colorScheme[fieldId as keyof IColorScheme];
          }

          if (!metadata) return;

          return (
            <div className={styles.tableBody}>
              <div className={styles.tableChild}>  {`${metadata.textName}`} </div>
              <div className={styles.tableChildText} >
                {`${defaultColor}`}
                {fieldId !== 'communityRID' && (
                  <div
                    style={{
                      height: '16px',
                      width: '16px',
                      backgroundColor: `${defaultColor}`,
                      marginLeft: '10px',
                      border: '1px #aaa solid',
                    }}
                  />
                )}
              </div>
              <div className={styles.tableChildEdit}>
                <label htmlFor="text">
                <textarea
                  id="text"
                  onChange={(e) => {
                    setEditedColorScheme({...editedColorScheme, ...{[fieldId]: e.currentTarget.value}});
                  }}
                  value={currentColor}
                  readOnly={fieldId === 'communityRID'}
                  required
                />
                {fieldId !== 'communityRID' && (
                  <div
                    style={{
                      height: '16px',
                      width: '16px',
                      backgroundColor: `${currentColor}`,
                      marginLeft: '10px',
                      border: '1px #aaa solid',
                    }}
                  />
                )}
                </label>
              </div>
              <div className={styles.tableChild}>  {`${metadata.info}`} </div>
            </div>
          );
        })
      }
      <Button
        type="button"
        startIcon={<Update />}
        sx={{ bgcolor: 'primary.light' }}
        variant="contained"
        onClick={updateColorSchemes}
      >
        UPDATE
      </Button>
    </div>
  );
}
