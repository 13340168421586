import React, { useEffect, useState } from 'react';
import styles from './CommunityInfoForm.module.css';
import {Button, ThemeOptions} from "@mui/material";
import useToken from "../../hooks/useToken";
import useInput from '../../hooks/useInput';
import {Add, Close} from "@mui/icons-material";
import useGeneralApiClient from '../../data/generalApiSource';

export default function ThemeForm(props: {
  onClose: () => void,
  onSave: () => void,
  initialThemes: Record<string, ThemeOptions> | undefined
}) {
  const {onClose, onSave, initialThemes} = props;
  const {adminToken} = useToken();
  const generalApiSource = useGeneralApiClient();
  //const [themes, setThemes] = useState<Record<string, ThemeOptions>>({});
  const {onChange: onThemesChange, value: themes} = useInput(initialThemes ? JSON.stringify(initialThemes, (k, v) => v ?? undefined, 2) : `{
  "Bimaire": {
      "typography": {
          "fontFamily": "Roboto, Helvetica, sans-serif"
      },
      "palette": {
          "primary": {
              "main": "#4098BB"
          },
          "secondary": {
              "main": "#4098BB"
          },
          "text": {
              "primary": "#6D6E71",
              "secondary": "#6D6E71"
          },
          "background": {
              "default": "#D9E0E280"
          }
      }
  }
}`);

  // const {onChange: onThemeChange, value: currentTheme} = useInput(themes. ? selectedCommunityInfo.theme : '');
  // const {onChange: onPrimaryColorChange, value: primaryColor} = useInput(themes[currentTheme] && themes[currentTheme].palette !== undefined && !(themes[currentTheme].palette)?.primary is SimplePaletteColorOptions ? themes[currentTheme].palette?.primary.main : '');
  // const {onChange: onSecondaryColorChange, value: secondaryColor} = useInput(selectedCommunityInfo ? JSON.stringify(selectedCommunityInfo.content, (k, v) => v ?? undefined, 2) : '{ "welcome": {"contentUrl": "welcome.html"}, "OptSelID": {"contentUrl": "optsel.html"}, "OptValID": {"textPrelude": "PLACEHOLDER info"} }');
  // const {onChange: onPrimaryTextChange, value: primaryText} = useInput(selectedCommunityInfo ? selectedCommunityInfo.id.split('_')[0] : 0);
  // const {onChange: onSecondaryTextChange, value: secondaryText} = useInput(selectedCommunityInfo ? selectedCommunityInfo.id.split('_')[2] : 0);
  // const {onChange: onBackgroundChange, value: background} = useInput(selectedCommunityInfo ? selectedCommunityInfo.id.split('_')[2] : 0);
  // const {onChange: onTypographyChange, value: typography} = useInput(selectedCommunityInfo ? selectedCommunityInfo.id.split('_')[2] : 0);

  // useEffect(() => {
  //   if (initialThemes)
  //     setThemes(initialThemes);
    
  // }, [initialThemes]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!themes) {
      alert('All fields need to be filled!');
      return;
    }

    try {
   
      await generalApiSource.updateOptionConfigThemes(adminToken!, JSON.parse(themes)).then(() => {
        onSave();
      })
      
    } catch (err) {
      alert(err);
    }
  };

  return (
    <div className={styles.container}>
      <h1>{initialThemes ? 'Update' : 'Add'} Themes</h1>
      <form onSubmit={handleSubmit}>
      
        <label htmlFor="optSels">
          Themes
          <textarea
            id="optSels"
            name="optSels"
            onChange={onThemesChange}
            placeholder=''
            required={true}
            rows={15}
            cols={40}
          >
            {themes}
          </textarea>
        </label>
                
        <Button
          type="submit"
          startIcon={<Add />}
          sx={{ bgcolor: 'primary.light' }}
          variant="contained"
        >
          SUBMIT
        </Button>
        <Button
          onClick={() => onClose()}
          startIcon={<Close />}
          sx={{ bgcolor: 'primary.light', marginLeft:'20px' }}
          variant="contained"
        >
          CLOSE
        </Button>
      </form>
    </div>
  );
}
