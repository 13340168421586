import {Backdrop, Card, CardHeader} from "@mui/material";
import React, {useState} from "react";
import ColorSchemeGrid from "./colorschemes/ColorSchemeGrid";
import LoadingIndicator from "../../components/LoadingIndicator";

export default function ModifyColorScheme() {
  const [isLoadingIndicatorActive, setIsLoadingIndicatorActive] = useState(false);

  return (
    <>
      <Card>
        <CardHeader title={"Color Schemes"} />
        <ColorSchemeGrid setIsLoadingIndicatorActive={setIsLoadingIndicatorActive}/>
      </Card>
      {isLoadingIndicatorActive && (
        <Backdrop
          open={isLoadingIndicatorActive}
          sx={{ bgcolor: 'rgba(0, 0, 0, 0.6)', color: '#fff', position: 'absolute' }}
        >
          <LoadingIndicator
            color="#fff"
            isLoading={isLoadingIndicatorActive}
            //isLoadingIndicatorActiveHandler={setIsLoadingIndicatorActive}
            message='Loading colors...'
          />
        </Backdrop>
      )}
    </>
  );
}
