import {List, ListItemButton, ListItemText} from "@mui/material";
import React from "react";
import {INotification} from "../../api/adminApiClient";

export function MessagesList(props: { messages: INotification[] | undefined, openEdit: (message:INotification) => void }) {
  const {messages, openEdit} = props;

  return (
    <List>
      {messages?.map((message) => (
        <ListItemButton
          key={message.id}
          onClick={(e) => {
            openEdit(message);
          }}
          sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.25)" }}
        >
          <ListItemText primary={`${message.date} - ${message.header}`} secondary={`${message.text}`}/>
        </ListItemButton>
      ))}
    </List>
  );
}
