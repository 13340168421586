import {useState} from 'react';

const ADMIN_TOKEN =  'admintoken';

export class AdminToken {
    token: string | null = null;
    kovaToken: string | null = null;
}

export default function useAdminToken() {
    const getToken = () => {
        const tokenString = sessionStorage.getItem(ADMIN_TOKEN);
        if (!tokenString) {
            return null;
        }

        const userToken : AdminToken = JSON.parse(tokenString);
        return userToken
    };

    const removeToken = () => {
        sessionStorage.removeItem(ADMIN_TOKEN);
        setAdminToken(null);
    }

    const saveToken = (userToken: AdminToken) => {
        sessionStorage.setItem(ADMIN_TOKEN, JSON.stringify(userToken));
        setAdminToken(userToken);
    };

    const setOrRemoveAdminToken = (userToken: AdminToken | null) => {
        if (userToken) {
            saveToken(userToken);
        }
        else {
            removeToken();
        }
    };

    const [adminToken, setAdminToken] = useState<AdminToken | null>(getToken());

    return {
        setAdminToken:setOrRemoveAdminToken,
        adminToken
    }
}
