import {Link, Typography} from "@mui/material";
import React from "react";
const { version } = require('../../package.json');

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.bimaire.com/">
        BIMaire
      </Link>{' '}
      {new Date().getFullYear()}
      {' - '}
      {`V ${version}`}
    </Typography>
  );
}

export default Copyright;
