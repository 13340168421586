import React, {useEffect, useState} from "react";
import LoadingIndicator from "../../components/LoadingIndicator";
import {Backdrop, Card, CardHeader, Snackbar, useMediaQuery, useTheme} from "@mui/material";
import useToken from "../../hooks/useToken";
import styles from './Models.module.css';

import Search from "../../components/search/Search";
import useGeneralApiClient from "../../data/generalApiSource";
import {ApiClientResult, IKovaModel} from "../../api/generalApiClient";
import {ModelsGrid} from "./ModelsGrid";

const handleSearch = (models: IKovaModel[], value: string) => {
  if (models && value) {
    const filteredModels = models
      .filter((model) =>  {
        return model.Name.toLowerCase().includes(value.toLowerCase()) ||
            model.ModelRID === Number(value.toLowerCase()) ||
            model.ModelID.toLowerCase().includes(value.toLowerCase()) ||
            model.Status.toLowerCase() == value.toLowerCase()
      });
    return filteredModels;
  }
  return models;
};

interface IModelsProps {
  errorHandler: (error: Error) => void
}

export function Models(props: IModelsProps) {

  const {errorHandler} = props;

  const refreshIFPOptCfgImgs = async (modelrid: number) =>  {
    if(adminToken) {
      setRefreshingOptCfgImgs(true);
      try {
        await generalApiSource.refreshIFPOptCfgImgs(adminToken, modelrid);
      }
      catch (e){
        errorHandler(e as Error);
      }
      finally {
        setRefreshingOptCfgImgs(false);
      }
    }
  };

  const {adminToken} = useToken();
  const generalApiSource = useGeneralApiClient();


  const [refreshingOptCfgImgs, setRefreshingOptCfgImgs] = useState<boolean>(false);
  const [models, setModels] = useState<ApiClientResult<IKovaModel[]> | undefined>(undefined);
  const [open, setOpen] = React.useState(false);
  const [searchText, setSearchText] = useState('');
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    document.title = 'Models';
  });

  useEffect(() => {
    generalApiSource.getModels(adminToken!).then(models => {
      setModels(models);
      setOpen(models.wasCached);
    }).catch(error => {
      props.errorHandler(error);
    });
  }, [adminToken]);

  const filteredModels = models !== undefined ? handleSearch(models.result, searchText) : undefined;

  const isLoadingIndicatorActive = models === undefined;

  return (
    <>
      <Card
        className={styles.modelCard}
        sx={{
          width: "100%",
          height: "100%"
        }}
      >
        <CardHeader
          subheader="All current models"
          subheaderTypographyProps={{ variant: 'body2' }}
          sx={{ marginBottom: 2, padding: 0 }}
          title="All Models"
          titleTypographyProps={{ variant: 'h5', sx: { textTransform: 'capitalize' }}}
        />
        <Search
          onChange={setSearchText}
          placeholder="Search by model name, rid, id or status..."
        />
        { filteredModels && (
          isSmUp ?
            (<ModelsGrid models={filteredModels} refreshIFPOptCfgImgs={refreshIFPOptCfgImgs}/>) :
              (<div>TODO</div>)
        )}
        <Snackbar
          anchorOrigin={{vertical:'bottom',horizontal:'center'}}
          open={open}
          autoHideDuration={3000}
          message={`Loaded from a local copy (${models?.getTimeLeftText()} left...)`}
          onClose={() => setOpen(false)}
          sx={{ bottom: { xs: 90, sm: 30 } }}
        />
      </Card>
      <Backdrop
        open={isLoadingIndicatorActive || refreshingOptCfgImgs}
        sx={{ bgcolor: 'rgba(0, 0, 0, 0.6)', color: '#fff', position: 'absolute' }}
      >
        <LoadingIndicator
          color="#fff"
          isLoading={isLoadingIndicatorActive || refreshingOptCfgImgs}
          message={refreshingOptCfgImgs ? 'Refreshing Option Images...' : 'Loading models...'}
        />
      </Backdrop>
    </>
  );
}
