import React, {useEffect} from "react";
import {Grid, Paper} from "@mui/material";

export function Dashboard() {
    useEffect(() => {
        document.title = 'Dashboard';
    });

    return (
          <Grid container spacing={2} sx={{ height: '100%', paddingBottom: 2, paddingTop: 2 }}>
              <Grid item xs={6} sx={{ height: '50%' }}>
                  <Paper square sx={{ backgroundColor: '#fff', height: '100%' }} />
              </Grid>
              <Grid item xs={3} sx={{ height: '50%' }}>
                  <Paper square sx={{ backgroundColor: '#fff', height: '100%' }} />
              </Grid>
              <Grid item xs={3} sx={{ height: '50%' }}>
                  <Paper square sx={{ backgroundColor: '#fff', height: '100%' }} />
              </Grid>
              <Grid item xs={4.5} sx={{ height: '50%' }}>
                  <Paper square sx={{ backgroundColor: '#fff', height: '100%' }} />
              </Grid>
              <Grid item xs={4.5} sx={{ height: '50%' }}>
                  <Paper square sx={{ backgroundColor: '#fff', height: '100%' }} />
              </Grid>
              <Grid item xs={3} sx={{ height: '50%' }}>
                  <Paper square sx={{ backgroundColor: '#fff', height: '100%' }} />
              </Grid>
      </Grid>
    );
}
