import React, {useEffect, useState} from "react";
import LoadingIndicator from "../../components/LoadingIndicator";
import {Backdrop, Card, CardHeader, useMediaQuery, useTheme} from "@mui/material";
import useToken from "../../hooks/useToken";
import styles from './Communities.module.css';
import Search from "../../components/search/Search";
import {useLocation, useParams} from "react-router-dom";
import {LotsList} from "./LotsList";
import {LotsGrid} from "./LotsGrid";
import useGeneralApiClient from "../../data/generalApiSource";
import {CommunityLotData, IKovaCommunity} from "../../api/generalApiClient";

const handleSearch = (lots: CommunityLotData[] | undefined, value: string) => {
  if (lots && value) {
    const filteredLots = lots
      .filter((lot) =>  {
        return lot.Name.toLowerCase().includes(value.toLowerCase()) ||
          lot.Customer.Name.toLowerCase().includes(value.toLowerCase()) ||
          lot.LotRID === Number(value.toLowerCase()) ||
          lot.Status.toLowerCase() == value.toLowerCase()
      });
    return filteredLots;
  }
  return lots;
};

interface ICommunityLoaderProps {
  errorHandler: (error: Error) => void
}

interface ICommunityLocation {
  community:IKovaCommunity
}

export function CommunityFromRoute(props: ICommunityLoaderProps) {
  const { communityRID } = useParams();
  const location = useLocation();
  if (location.state)
  {
    var locationState = location.state as ICommunityLocation;
    if (locationState.community) {
      return <Community errorHandler={props.errorHandler} community={locationState.community} />
    }
  }

  alert('Could not find community information!');
  return null;
}

interface ICommunityProps {
  errorHandler: (error: Error) => void
  community:IKovaCommunity
}

export function Community(props: ICommunityProps) {
  const {adminToken} = useToken();
  const generalApiSource = useGeneralApiClient();
  const [lots, setLots] = useState<CommunityLotData[] | undefined>(undefined);
  const [searchText, setSearchText] = useState('');
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const {community} = props;

  useEffect(() => {
    document.title = community.Name;
  });

  useEffect(() => {
    generalApiSource.getCommunityLotData(adminToken!, community.CommunityRID).then(lots => {
      setLots(lots);
    }).catch(error => {
      props.errorHandler(error);
    });
  }, [adminToken, community.CommunityRID]);

  const filteredLots = handleSearch(lots, searchText);

  const isLoadingIndicatorActive = lots === undefined;

  return (
    <>
      <Card
        className={styles.communityCard}
        sx={{
          width: "100%",
          height: "100%"
        }}
      >
        <CardHeader
          subheader={`Community details`}
          subheaderTypographyProps={{ variant: 'body2' }}
          sx={{ marginBottom: 2, padding: 0 }}
          title={`${community?.Name}`}
          titleTypographyProps={{ variant: 'h5', sx: { textTransform: 'capitalize' }}}
        />
        <Search
          onChange={setSearchText}
          placeholder="Search by lot name, rid, id or status..."
        />
        {isSmUp ?
          (<LotsGrid lots={filteredLots} />) :
          (<LotsList lots={filteredLots} />)}
      </Card>
      <Backdrop
        open={isLoadingIndicatorActive}
        sx={{ bgcolor: 'rgba(0, 0, 0, 0.6)', color: '#fff', position: 'absolute' }}
      >
        <LoadingIndicator
          color="#fff"
          isLoading={isLoadingIndicatorActive}
          message='Loading lots...'
        />
      </Backdrop>
    </>
  );
}
