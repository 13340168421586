import ApiClient from "./apiClient";
import {AdminToken} from "../hooks/useToken";
import GlobalConfig from "../utils/GlobalConfig";
import { ICommunityInfo, IManifest} from "./manifest";
import { ThemeOptions } from "@mui/material";


export interface IKovaCommunity {
  CommunityRID: number;
  BUnitRID: number;
  Name: string;
  CommunityID: string;
  Status: string;
  State: string;
}

export interface  IKovaCommunityConcise {
  CommunityRID: number;
  CommunityName: string;
}

export interface IKovaModel {
  ModelRID: number;
  Name: string;
  ModelID: string;
  Status: string;
}

export interface CommunityLotData {
  SlsOrdRID: number;
  LotRID: number;
  CustomerRID: number;
  ModelRID: number;
  CurrentHomeRID: number;
  CurrentJobRID: number;
  IsMarketHomeSO: boolean;
  SalesRepName:string;
  HouseName:string;
  Name: string;
  Description: string;
  Status: string;
  Lot : KovaLot;
  Customer : KovaCustomer;
  CurrentJob : KovaJob;
  CurrentHome : KovaHome;
}

export interface KovaLot {
  LotRID: number;
  Name: string;
  LotNumber: number;
  StreetAddress: string;
  Status: string;
}

export interface KovaSlsWsh {
  CustomerRID: number;
  CustName: string;
}

export interface KovaCustomer {
  CustomerRID: number;
  Name: string;
  EmailHome: string;
  PhoneHome: string;
}

export interface KovaHome {
  HomeRID: number;
  Name: string;
  Status: string;
  WtyStatus:string;
}

export interface KovaJob {
  JobRID: number;
  Name: string;
  Status: string;
}

export class ApiClientResult<ResultObject> {
  wasCached:boolean;
  timeLeft:number;
  result:ResultObject;

  constructor(wasCached:boolean, timeLeft:number, result:ResultObject) {
    this.wasCached = wasCached;
    this.timeLeft = timeLeft;
    this.result = result;
  }

  getTimeLeftText() {
    const seconds = Math.floor(this.timeLeft / 1000);
    if (seconds > 60) {
      const minutes = Math.floor(seconds / 60);
      return `${minutes} minutes`
    }
    else {
      return `${seconds} seconds`
    }
  }
}

export interface IApiClient {
  getCommunities(apiToken: AdminToken): Promise<ApiClientResult<IKovaCommunity[]>>;
  getModels(apiToken: AdminToken): Promise<ApiClientResult<IKovaModel[]>>;
  getCommunityLotData(apiToken: AdminToken, communityRID: number): Promise<CommunityLotData[]>;
}

class GeneralApiClient implements IApiClient {

  private kovaApiClient: ApiClient;
  private communityCacheTimeout: number;
  private cachedCommunities: IKovaCommunity[];

  constructor() {
    this.kovaApiClient = new ApiClient(`${GlobalConfig.instance().KOVA_API_URL}admincenter/`);
    this.communityCacheTimeout = 0;
    this.cachedCommunities = [];
  }

  async getCommunities(apiToken: AdminToken): Promise<ApiClientResult<IKovaCommunity[]>> {
    var seconds = new Date().getTime();
    var timeLeft = this.communityCacheTimeout - seconds
    if (timeLeft > 0) {
      return new ApiClientResult<IKovaCommunity[]>(
        true,
        timeLeft,
        this.cachedCommunities
      );
    }

    const response = await this.kovaApiClient.get('Communities', apiToken);

    if (response.ok)
    {
      var communities = await response.json() as IKovaCommunity[];
      this.cachedCommunities = communities;
      timeLeft = 300000;//Cache for 5 minutes
      this.communityCacheTimeout =  new Date().getTime() + timeLeft;
      return new ApiClientResult<IKovaCommunity[]>(
        false,
        timeLeft,
        communities
      );
    }

    return this.kovaApiClient.handleNonOkReponse<ApiClientResult<IKovaCommunity[]>>(response);
  }

  async getModels(apiToken: AdminToken): Promise<ApiClientResult<IKovaModel[]>> {
    const response = await this.kovaApiClient.get('Models', apiToken);

    if (response.ok)
    {
      let models = await response.json() as IKovaModel[];
      return new ApiClientResult<IKovaModel[]>(false, 0, models);
    }

    return this.kovaApiClient.handleNonOkReponse<ApiClientResult<IKovaModel[]>>(response);
  }


  async getCommunityLotData(apiToken: AdminToken, communityRID: number): Promise<CommunityLotData[]> {
    const response = await this.kovaApiClient.get(`CommunityLotData?CommunityRID=${communityRID}`, apiToken);

    if (response.ok)
    {
      return await response.json() as CommunityLotData[];
    }

    return this.kovaApiClient.handleNonOkReponse<CommunityLotData[]>(response);
  }

  async refreshIFPOptCfgImgs(apiToken: AdminToken, modelrid:number):Promise<Response> {
    const response = await this.kovaApiClient.save(`RefreshIFPOptCfgImgs/${modelrid}`, apiToken,"");

    if (response.ok)
    {
      return response;
    }
    return this.kovaApiClient.handleNonOkReponse<Response>(response);
  }

  async getOptionConfig(apiToken: AdminToken):Promise<IManifest> {
    const response = await this.kovaApiClient.get('optionDataConfig', apiToken);

    if (response.ok)
    {
      let responseJson = await response.json();
      return responseJson as IManifest;
    }

    return this.kovaApiClient.handleNonOkReponse<IManifest>(response);
  }

  async updateOptionConfig(apiToken: AdminToken, communityInfo: ICommunityInfo, communityId: string, modelId: string):Promise<Response> {
    let bodyJson = JSON.stringify(communityInfo);
    const response = await this.kovaApiClient.save(`optionDataConfig/community/${communityId}/model/${modelId}`, apiToken, bodyJson);

    if (response.ok)
    {
      return response;
    }

    return this.kovaApiClient.handleNonOkReponse<Response>(response);
  }

  async deleteOptionConfig(apiToken: AdminToken, communityId: string, modelId: string):Promise<Response> {
    const response = await this.kovaApiClient.delete(`optionDataConfig/community/${communityId}/model/${modelId}`, apiToken);

    if (response.ok)
    {
      return response;
    }

    return this.kovaApiClient.handleNonOkReponse<Response>(response);
  }

  async getOptionConfigThemes(apiToken: AdminToken):Promise<Record<string, ThemeOptions>> {
    const response = await this.kovaApiClient.get('optionDataConfig/themes', apiToken);

    if (response.ok)
    {
      let responseJson = await response.json();
      return responseJson as Record<string, ThemeOptions>;
    }

    return this.kovaApiClient.handleNonOkReponse<Record<string, ThemeOptions>>(response);
  }

  async updateOptionConfigThemes(apiToken: AdminToken, themes: Record<string, ThemeOptions>):Promise<Response> {

    let bodyJson = JSON.stringify(themes);
    const response = await this.kovaApiClient.save(`optionDataConfig/themes`, apiToken, bodyJson);

    if (response.ok)
    {
      return response;
    }

    return this.kovaApiClient.handleNonOkReponse<Response>(response);
  }

  async refreshOptionData(apiToken: AdminToken, bunitRid: number, modelRid: number, communityRid: number, communityId: string, modelId: string, optSels: string[]):Promise<Response> {
    let bodyJson = JSON.stringify({"bunitRID": bunitRid, "modelRID": modelRid, "communityId": communityId, "modelId": modelId, "optSels": optSels, "communityRID": communityRid});
    const response = await this.kovaApiClient.save('refreshOptionData', apiToken, bodyJson);

    if (response.ok)
    {
      return response;
    }

    return this.kovaApiClient.handleNonOkReponse<Response>(response);
  }
}


export default GeneralApiClient;
