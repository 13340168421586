import AdminApiClient from "../api/adminApiClient";
import React, {useContext} from "react";

export const AdminApiClientContext = React.createContext<AdminApiClient|null>(null);

function useAdminApiClient() {
    const retval = useContext(AdminApiClientContext);
    if (retval === null) throw new Error('AdminApiClient not initialized!');
    return retval;

}

export default useAdminApiClient;
