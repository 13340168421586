import {useEffect, useRef, useState} from 'react';
import {Box, InputBase} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import styles from './Search.module.css';

interface SearchProps {
  onChange: (value: string) => any;
  placeholder: string;
}

export default function Search({ onChange, placeholder }: SearchProps) {
  const [value, setValue] = useState('');
  const timer = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (timer.current !== null) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      onChange(value);
    },1000)

  },[value])

  return (
    <Box
      className={styles.Search}
      component="div"
      sx={{ borderRadius: 1 }}
    >
      <InputBase
        onChange={(e) => setValue(e.target.value)}
        placeholder={placeholder}
        sx={{ padding: 1 , width: '100%' }}
        value={value}
      />
      <Box
        className={styles.searchIconWrapper}
        component="div"
        sx={{ px: 1 }}
      >
        <SearchIcon />
      </Box>
    </Box>
  )
}
