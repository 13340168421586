import { Add } from "@mui/icons-material";
import { Backdrop, Button, Card, CardContent, CardHeader, Modal, ThemeOptions } from "@mui/material";
import { useEffect, useState } from "react";
import { IKovaCommunity, IKovaModel } from "../../api/generalApiClient";
import { ICommunityInfo, ICommunityInfoConfig, IManifest } from "../../api/manifest";
import LoadingIndicator from "../../components/LoadingIndicator";
import useGeneralApiClient from "../../data/generalApiSource";
import useToken from "../../hooks/useToken";
import CommunityInfoForm from "./CommunityInfoForm";
import { CommunityInfoGrid } from "./CommunityInfoGrid";
import ThemeForm from "./ThemeForm";

interface ICommunityInfoProps {
    errorHandler: (error:Error) => void;
    communities: IKovaCommunity[] | undefined;
    models: IKovaModel[] | undefined;
  }

export default function CommunityInfoConfig(props: ICommunityInfoProps) {
    const {errorHandler, communities, models} = props;
    const generalApiSource = useGeneralApiClient();
    const {adminToken} = useToken();
    const [selectedCommunityInfo, setSelectedCommunityInfo] = useState<ICommunityInfoConfig>();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openThemeModal, setOpenThemeModal] = useState<boolean>(false);
    const [isLoadingIndicatorActive, setIsLoadingIndicatorActive] = useState(false);
    
    const [communityInfo, setCommunityInfo] = useState<IManifest|undefined>(undefined);
    const [themes, setThemes] = useState<Record<string, ThemeOptions> | undefined>(undefined);
    useEffect(() => {
        loadConfigs();

        loadThemes();
        
    }, []);

    function loadThemes() {
      generalApiSource.getOptionConfigThemes(adminToken!).then(themeConfig => {
        setThemes(themeConfig);
      }).catch(error => {
        //probably missing which is fine
        console.log("Error loading AWO themes: " + error);
      });
    }

    function loadConfigs() {
        setIsLoadingIndicatorActive(true);
        generalApiSource.getOptionConfig(adminToken!).then(info => {
            setCommunityInfo(info)
        }).catch(error => {
            console.log("Error loading AWO configs: " + error);
            //show error in grid instead of popup
        }).finally(() => setIsLoadingIndicatorActive(false));
    }

    function refreshOptionData(bunitRid: number, modelRid: number, optSels: string[]) {
        setIsLoadingIndicatorActive(true);
        const community = communities?.find(c => c.BUnitRID === bunitRid);
        const communityId = community?.CommunityID ?? '';
        const communityRid = community?.CommunityRID ?? 0;
        const modelId = models?.find(m => m.ModelRID === modelRid)?.ModelID ?? ''
        generalApiSource.refreshOptionData(adminToken!, bunitRid, modelRid, communityRid, communityId, modelId, optSels).then(resp => {
            loadConfigs();
        }).catch(error => {
            errorHandler(error);
        });
    }

    function handleAddClick() {
        try {
          setSelectedCommunityInfo(undefined);
          setOpenModal(true);
        } catch (err) {
          alert('Add failed!');
        }
      }
    
      function handleEditClick(id:string) {
        try {
          const info = communityInfo?.communities.find(page => page.id === id);

          if (!info) throw new Error("Community Info not found");

          const infoRIDs = info.id.split('_');
            
          let config : ICommunityInfoConfig = {
            id: info.id,
            bunitRID: +infoRIDs[0],
            modelRID: +infoRIDs[2],
            theme: info.theme,
            status: info.status,
            name: info.name,
            filename: info.filename,
            skuFilename: info.skuFilename,
            ociFilename: info.ociFilename,
            fetchedAt: info.fetchedAt,
            optSels: JSON.stringify(info.optSels, (k, v) => v ?? undefined, 2),
            content: info.content ? JSON.stringify(info.content, (k, v) => v ?? undefined, 2) : "",
            optSelChain: info.optSelChain?.replaceAll('=-', ','),
            modelAvails: info.modelAvails,
            dataSources: info.dataSources

          };
          setSelectedCommunityInfo(config);
          setOpenModal(true);
        } catch (err) {
          alert('Edit failed!');
        }
      }
    
      function handleDeleteClick(bunitRid: number, modelRid: number) {
        setIsLoadingIndicatorActive(true);
        const communityId = communities?.find(c => c.BUnitRID === bunitRid)?.CommunityID ?? ''
        const modelId = models?.find(m => m.ModelRID === modelRid)?.ModelID ?? ''
        generalApiSource.deleteOptionConfig(adminToken!, communityId, modelId).then(resp => {
            loadConfigs();
        }).catch(error => {
            errorHandler(error);
        })
      }

    return <Card sx={{width: '100%', height: '100%'}}>
        <CardHeader title="Advanced Web Options Configurations" />
        <CardContent>

        {communityInfo && communities && models &&
            <CommunityInfoGrid communities={communityInfo.communities} 
                refreshOptionData={refreshOptionData} 
                handleEdit={handleEditClick}
                handleDelete={handleDeleteClick}
                kovacommunities={communities}
                models={models}
            />}
        
        {communityInfo && <Button
          onClick={() => handleAddClick()}
          startIcon={<Add />}
          sx={{ bgcolor: 'primary.light' }}
          variant="contained"
        >
          ADD
        </Button>}

        {communityInfo && <Button
          onClick={() => setOpenThemeModal(true)}
          startIcon={<Add />}
          sx={{ bgcolor: 'primary.light', marginLeft:'20px' }}
          variant="contained"
        >
          UPDATE THEMES
        </Button>}

        {communityInfo === undefined && <span>Unable to load configurations</span>}
        
        </CardContent>

        {isLoadingIndicatorActive && 
            <Backdrop
            open={isLoadingIndicatorActive}
            sx={{ bgcolor: 'rgba(0, 0, 0, 0.6)', color: '#fff', position: 'absolute' }}
            >
            <LoadingIndicator
                color="#fff"
                isLoading={isLoadingIndicatorActive}
                message='Loading Community Info...'
            />
            </Backdrop>
        }

        <Modal
        slotProps={{ backdrop: { sx: { position: 'absolute' }}}}
        container={() => document.getElementById('main')}
        open={openModal}
        sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}
      >
        <CommunityInfoForm onClose={() => setOpenModal(false)} 
            onSave={() => {setOpenModal(false); loadConfigs();}} 
            selectedCommunityInfo={selectedCommunityInfo} 
            communities={communities?.filter(c => c.BUnitRID > 0)}
            models={models?.filter(m => m.ModelRID > 0)}
         />
      </Modal>

      <Modal
        slotProps={{ backdrop: { sx: { position: 'absolute' }}}}
        container={() => document.getElementById('main')}
        open={openThemeModal}
        sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}
      >
        <ThemeForm onClose={() => setOpenThemeModal(false)} 
            onSave={() => {setOpenThemeModal(false); loadThemes(); }} 
            initialThemes={themes}
         />
      </Modal>

    </Card>
}