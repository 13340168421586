import {Typography} from '@mui/material';
import styles from './LoadingIndicator.module.css';

type LoadingIndicatorProps = {
  color: string;
  isLoading: boolean;
  message?: string;
};

function LoadingIndicator({
  color,
  message
}: LoadingIndicatorProps) {
  return (
    <div className={styles.LoadingIndicator}>
      <div>
        <div style={{ borderColor: `${color} transparent transparent transparent` }} data-testid="border-element" />
        <div style={{ borderColor: `${color} transparent transparent transparent` }} data-testid="border-element" />
        <div style={{ borderColor: `${color} transparent transparent transparent` }} data-testid="border-element" />
        <div style={{ borderColor: `${color} transparent transparent transparent` }} data-testid="border-element" />
      </div>
      {message && (
        <Typography>{message}</Typography>
      )}
    </div>
  );
}

export default LoadingIndicator;
