import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {Chip, Fab} from "@mui/material";
import React from "react";
import {ArrowForward} from "@mui/icons-material";
import useToken from "../../hooks/useToken";
import GlobalConfig from "../../utils/GlobalConfig";
import {CommunityLotData} from "../../api/generalApiClient";
import useAdminApiClient from "../../data/adminApiSource";


function setMarketHomeColor(isMarketHome: string) {
  switch(isMarketHome) {
    case 'Yes':
      return 'warning.main';
    case 'No':
      return 'success.main';
  }
}

export function setStatusColor(status: string) {
  switch(status) {
    case 'Available':
      return 'warning.main';
    case 'Sold':
      return 'success.main';
    default:
      return 'primary.main';
  }
}

function setHomeStatusColor(status: string) {
  switch(status) {
    case 'No Home':
    case 'Cancelled':
      return 'warning.main';
    case 'Closed':
      return 'success.main';
    default:
      return 'primary.main';
  }
}

function setJobStatusColor(status: string) {
  switch(status) {
    case 'No Job':
    case 'Revision':
    case 'BeingRevised':
    case 'Cancelled':
      return 'warning.main';
    case 'Completed':
      return 'success.main';
    default:
      return 'primary.main';
  }
}


function setWtyStatusColor(status: string) {
  switch(status) {
    case 'Warranty Expired':
      return 'warning.main';
    case 'In Warranty':
      return 'success.main';
    default:
      return 'primary.main';
  }
}

export function LotsGrid(props: { lots: CommunityLotData[] | undefined }) {
  const {adminToken} = useToken();
  const {lots} = props;
  const adminApiSource = useAdminApiClient();

  const columns: GridColDef[] = [
    { field: 'LotNumber', flex: 1, headerName: 'Lot #', valueGetter: (params) => params.row.Lot.LotNumber },
    { field: 'CustomerName', flex: 2, headerName: 'Customer Name', valueGetter: (params) => params.row.Customer.Name },
    {
      field: 'status',
      flex: 1,
      headerName: 'Status',
      renderCell: (params: any) => (
        <Chip
          label={params.row.Lot.Status}
          sx={{ borderColor: setStatusColor(params.row.Lot.Status), color: setStatusColor(params.row.Lot.Status) }}
          variant="outlined"
        />
      )
    },
    { field: 'isMarketHome', flex: 1, headerName: 'Market Home',
      renderCell: (params: any) => {
        const isMkt = params.row.IsMarketHomeSO ? 'Yes' : 'No';
        return (
          <Chip
            label={isMkt}
            sx={{ borderColor: setMarketHomeColor(isMkt), color: setMarketHomeColor(isMkt) }}
            variant="outlined"
          />
        )}
    },
    { field: 'homeStatus', flex: 1, headerName: 'Home',
      renderCell: (params: any) => params.row.CurrentHome?.Status && (
        <Chip
          label={params.row.CurrentHome?.Status}
          sx={{ borderColor: setHomeStatusColor(params.row.CurrentHome?.Status), color: setHomeStatusColor(params.row.CurrentHome?.Status) }}
          variant="outlined"
        />
      ) },
    {
      field: 'jobStatus', flex: 1, headerName: 'Job',
      renderCell: (params: any) => params.row.CurrentJob?.Status &&(
        <Chip
          label={params.row.CurrentJob?.Status}
          sx={{borderColor: setJobStatusColor(params.row.CurrentJob?.Status), color: setJobStatusColor(params.row.CurrentJob?.Status)}}
          variant="outlined"
        />
      )
    },
    {
      field: 'wtyStatus', flex: 1, headerName: 'Warranty',
      renderCell: (params: any) => params.row.CurrentHome?.WtyStatus &&(
          <Chip
              label={params.row.CurrentHome?.WtyStatus}
              sx={{borderColor: setWtyStatusColor(params.row.CurrentHome?.WtyStatus), color: setWtyStatusColor(params.row.CurrentHome?.WtyStatus)}}
              variant="outlined"
          />
      )
    },
    {
      field: 'Go',
      flex: 0,
      headerName: 'Go',
      renderCell: (params: any) => (
        <Fab color="primary" size={"small"} aria-label="go" sx={{
          ":hover":{
            backgroundColor:"primary.light"
          }
        }}>
          <ArrowForward />
        </Fab>
      )
    }
  ];

  return (
    <>
      {lots &&
        (
          <DataGrid
            columns={columns}
            onCellClick={(params => {
              if (params.colDef.field == "Go") {
                  const queryString = `/proxylogin?customerrid=${params.row.Customer.CustomerRID}`;
                  // Safari does not allow window.open in async calls, setTimeout runs on main thread
                  setTimeout(() => {
                    window.open(`${GlobalConfig.instance().CUSTOMER_PORTAL_URL}${queryString}`);
                  });
              }
            })}
            getRowId={(row:CommunityLotData) => row.LotRID}
            rows={lots}
            sx={{ border: 'none' }}
            disableRowSelectionOnClick={true}
            pagination
          />
        )
      }
    </>
  );
}
