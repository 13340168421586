import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {
    GridActionsCellItem,
    GridColDef,
    GridEditInputCell,
    GridEditSingleSelectCell,
    GridRenderEditCellParams, GridRowId, GridRowModes, GridRowModesModel
} from "@mui/x-data-grid";
import {Tooltip} from "@mui/material";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";

export interface CustomViewConfigurationGridColumnsProps {
    availableCustomViews: string[];
    rowModesModel: GridRowModesModel;
    handleSaveClick: (id: GridRowId) => void;
    handleCancelClick: (id: GridRowId) => void;
    handleEditClick: (id: GridRowId) => void;
    handleDeleteClick: (id: GridRowId) => void;
}

// Header and Name columns require custom inputs so that we can show Required field tooltips
function InputCellWithErrorTooltip(props: GridRenderEditCellParams) {
    const { error, colDef } = props;

    return (
        <Tooltip open={!!error} title={error}>
            <>
                {colDef.type === "singleSelect" && <GridEditSingleSelectCell {...props} />}
                {colDef.type === "string" && <GridEditInputCell {...props} />}
            </>
        </Tooltip>
    );
}

// Get specifications of columns and rows for Custom View Configurations grid.
export function CustomViewConfigurationGridColumns(props: CustomViewConfigurationGridColumnsProps) {
    const { availableCustomViews, handleDeleteClick, handleEditClick, handleCancelClick, handleSaveClick, rowModesModel } = props;

    const columns: GridColDef[] = [
        {field: 'name', flex: 2, headerName: 'Custom View name', type: 'singleSelect', valueOptions: availableCustomViews, editable: true, preProcessEditCellProps: (params) => {
                let errormessage = "Required field";
                return { ...params.props, error: !params.props.value && errormessage }
            },
            renderEditCell: InputCellWithErrorTooltip},
        {field: 'header', flex: 2, headerName: 'Header', editable: true, preProcessEditCellProps: (params) => {
                let errormessage = "Required field";
                return { ...params.props, error: !params.props.value && errormessage }
            },
            renderEditCell: InputCellWithErrorTooltip},
        {field: 'description', flex: 3, headerName: 'Description', editable: true},
        {field: 'sortOrder', flex: 1, headerName: 'Sort Order', type: 'number', editable: true},
        {field: 'show', flex: 1, headerName: 'Show', type: 'boolean', editable: true},
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            flex: 1,
            getActions: ({id}) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon/>}
                            label="Save"
                            sx={{
                                color: 'primary.main'
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon/>}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon/>}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon/>}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            }
        }
    ];

    return columns;
}