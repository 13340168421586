import {List, ListItemButton, ListItemText} from "@mui/material";
import React from "react";
import {useNavigate} from "react-router-dom";
import {IKovaCommunity} from "../../api/generalApiClient";

export function CommunitiesList(props: { communities: IKovaCommunity[] | undefined }) {
  const navigate = useNavigate();
  const {communities} = props;

  return (
    <List>
      {communities?.map(community => (
        <ListItemButton
          key={community.CommunityRID}
          onClick={() => {
            navigate(`/communities/${community.CommunityRID}`, {state:{community:community}});
          }}
          sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.25)" }}
        >
          <ListItemText primary={`${community.CommunityID} - ${community.Name}`} />
        </ListItemButton>
      ))}
    </List>
  );
}
