import GeneralApiClient from "../api/generalApiClient";
import React, {useContext} from "react";

export const GeneralApiClientContext = React.createContext<GeneralApiClient|null>(null);

function useGeneralApiClient() {
    const retval = useContext(GeneralApiClientContext);
    if (retval === null) throw new Error('GeneralApiClient not initialized!');

    return retval;

}

export default useGeneralApiClient;
