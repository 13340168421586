import React, {useEffect, useState} from "react";
import LoadingIndicator from "../../components/LoadingIndicator";
import {Backdrop, Card, CardHeader, Snackbar, useMediaQuery, useTheme} from "@mui/material";
import useToken from "../../hooks/useToken";
import styles from './Communities.module.css';
import {CommunitiesGrid} from "./CommunitiesGrid";
import {CommunitiesList} from "./CommunitiesList";
import Search from "../../components/search/Search";
import useGeneralApiClient from "../../data/generalApiSource";
import {ApiClientResult, IKovaCommunity} from "../../api/generalApiClient";

const handleSearch = (communities: IKovaCommunity[], value: string) => {
  if (communities && value) {
    const filteredCommunities = communities
      .filter((community) =>  {
        return community.Name.toLowerCase().includes(value.toLowerCase()) ||
          community.CommunityRID === Number(value.toLowerCase()) ||
          community.CommunityID.toLowerCase().includes(value.toLowerCase()) ||
          community.Status.toLowerCase() == value.toLowerCase()
      });
    return filteredCommunities;
  }
  return communities;
};

interface ICommunitiesProps {
  errorHandler: (error: Error) => void
}

export function Communities(props: ICommunitiesProps) {
  const {adminToken} = useToken();
  const generalApiSource = useGeneralApiClient();

  const [communities, setCommunities] = useState<ApiClientResult<IKovaCommunity[]> | undefined>(undefined);
  const [open, setOpen] = React.useState(false);
  const [searchText, setSearchText] = useState('');
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    document.title = 'Communities';
  });

  useEffect(() => {
    generalApiSource.getCommunities(adminToken!).then(communities => {
      setCommunities(communities);
      setOpen(communities.wasCached);
      //setIsLoadingIndicatorActive(false); Antti was here
    }).catch(error => {
      props.errorHandler(error);
    });
  }, [adminToken]);

  const filteredCommunities = communities !== undefined ? handleSearch(communities.result, searchText) : undefined;

  const isLoadingIndicatorActive = communities === undefined;

  return (
    <>
      <Card
        className={styles.communityCard}
        sx={{
          width: "100%",
          height: "100%"
        }}
      >
        <CardHeader
          subheader="All current communities"
          subheaderTypographyProps={{ variant: 'body2' }}
          sx={{ marginBottom: 2, padding: 0 }}
          title="All Communities"
          titleTypographyProps={{ variant: 'h5', sx: { textTransform: 'capitalize' }}}
        />
        <Search
          onChange={setSearchText}
          placeholder="Search by community name, rid, id or status..."
        />
        { filteredCommunities && (
          isSmUp ?
            (<CommunitiesGrid communities={filteredCommunities} />) :
            (<CommunitiesList communities={filteredCommunities} />)
        )}
        <Snackbar
          anchorOrigin={{vertical:'bottom',horizontal:'center'}}
          open={open}
          autoHideDuration={3000}
          message={`Loaded from a local copy (${communities?.getTimeLeftText()} left...)`}
          onClose={() => setOpen(false)}
          sx={{ bottom: { xs: 90, sm: 30 } }}
        />
      </Card>
      <Backdrop
        open={isLoadingIndicatorActive}
        sx={{ bgcolor: 'rgba(0, 0, 0, 0.6)', color: '#fff', position: 'absolute' }}
      >
        <LoadingIndicator
          color="#fff"
          isLoading={isLoadingIndicatorActive}
          message='Loading communities...'
        />
      </Backdrop>
    </>
  );
}
