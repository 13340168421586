import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {Chip, Fab} from "@mui/material";
import React from "react";
import {useNavigate} from "react-router-dom";
import {Refresh} from "@mui/icons-material";
import {IKovaModel} from "../../api/generalApiClient";
import useGeneralApiClient from "../../data/generalApiSource";
import useToken from "../../hooks/useToken";

export function setStatusColor(status: string) {
  switch(status) {
    case 'Open':
      return 'warning.main';
    case 'Closed':
      return 'success.main';
    default:
      return 'primary.main';
  }
}

interface IModelsGridProps{
  models: IKovaModel[];
  refreshIFPOptCfgImgs(modelrid: number) : void;
}

export function ModelsGrid(props: IModelsGridProps) {
  const {models, refreshIFPOptCfgImgs} = props;

  const columns: GridColDef[] = [
    {field: 'ModelRID', flex: 0, headerName: 'RID'},
    {field: 'ModelID', flex: 1, headerName: 'ID'/*, renderCell: (params) => (
      <span className={styles.fakeLink}>{params.value}</span>
    )*/},
    {field: 'Name', flex: 2, headerName: 'Name'},
    {
      field: 'Status',
      flex: 1,
      headerName: 'Status',
      renderCell: (params: any) => (
        <Chip
          label={params.value}
          sx={{borderColor: setStatusColor(params.value), color: setStatusColor(params.value)}}
          variant="outlined"
        />
      )
    },
    {
      field: 'IFPImgs',
      flex: 0,
      headerName: 'IFP Imgs',
      renderCell: (params: any) => (
        <Fab color="primary" size={"small"} aria-label="go" sx={{
          ":hover":{
            backgroundColor:"primary.light"
          }
        }}>
          <Refresh />
        </Fab>
      )
    }
  ];

  return (
    <>
      {
        (
          <DataGrid
            columns={columns}
            onCellClick={(params => {
              if (params.colDef.field == "IFPImgs") {
                refreshIFPOptCfgImgs(params.row.ModelRID);
              }
            })}
            getRowId={(row:IKovaModel) => row.ModelRID}
            rows={models}
            sx={{ border: 'none' }}
            disableRowSelectionOnClick={true}
            pagination
          />
        )
      }
    </>
  );
}
