import {createTheme} from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: '1.5rem',
          fontWeight: 700
        }
      }
    }
  },
  palette: {
    primary: {
      light: '#7986CB',
      main: '#3F51B5'
    },
    secondary: {
      main: '#F50057'
    },
    success: {
      main: '#4CAF50'
    },
    warning: {
      main: '#ED6C02'
    }
  },
});

export default theme;
