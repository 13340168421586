import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {Chip, Fab} from "@mui/material";
import React from "react";
import {useNavigate} from "react-router-dom";
import {ArrowForward} from "@mui/icons-material";
import {IKovaCommunity} from "../../api/generalApiClient";

export function setStatusColor(status: string) {
  switch(status) {
    case 'Open':
      return 'warning.main';
    case 'Closed':
      return 'success.main';
    default:
      return 'primary.main';
  }
}

export function CommunitiesGrid(props: { communities: IKovaCommunity[]}) {
  const navigate = useNavigate();
  const {communities} = props;

  const columns: GridColDef[] = [
    {field: 'CommunityRID', flex: 0, headerName: 'RID'},
    {field: 'CommunityID', flex: 1, headerName: 'ID'/*, renderCell: (params) => (
      <span className={styles.fakeLink}>{params.value}</span>
    )*/},
    {field: 'Name', flex: 2, headerName: 'Name'},
    {
      field: 'Status',
      flex: 1,
      headerName: 'Status',
      renderCell: (params: any) => (
        <Chip
          label={params.value}
          sx={{borderColor: setStatusColor(params.value), color: setStatusColor(params.value)}}
          variant="outlined"
        />
      )
    },
    {
      field: 'Go',
      flex: 0,
      headerName: 'Go',
      renderCell: (params: any) => (
        <Fab color="primary" size={"small"} aria-label="go" sx={{
          ":hover":{
            backgroundColor:"primary.light"
          }
        }}>
          <ArrowForward />
        </Fab>
      )
    }/*,
    {
      field: 'styleassignment',
      flex: 1,
      headerName: 'Style',
      renderCell: (params: any) => (
        <Chip
          label={params.value}
          sx={{
            borderColor: params.value === 'default' ? 'primary.main' : 'secondary.main',
            color: params.value === 'default' ? 'primary.main' : 'secondary.main'
          }}
          variant='outlined'
        />
      )
    },*/
  ];

  return (
    <>
      {
        (
          <DataGrid
            columns={columns}
            onCellClick={(params => {
              if (/*params.colDef.field == "CommunityID" || */params.colDef.field == "Go") {
                navigate(`/communities/${params.row.CommunityRID}`, {state:{community:params.row}});
              }
            })}
            getRowId={(row:IKovaCommunity) => row.CommunityRID}
            rows={communities}
            sx={{ border: 'none' }}
            disableRowSelectionOnClick={true}
            pagination
          />
        )
      }
    </>
  );
}
