import {Card, CardHeader} from "@mui/material";
import React from "react";
import ConfigurationGrid from "./configuration/ConfigurationGrid";

export default function ModifyConfiguration() {

  return (
    <>
      <Card>
        <CardHeader title={"Configurations"} />
        <ConfigurationGrid />
      </Card>
    </>
  );
}
