import {Backdrop, Box, Card, CardHeader} from "@mui/material";
import React, {useEffect, useState} from 'react';
import {useTheme} from "@mui/material/styles";
import styles from './Branding.module.css';
import ModifyCustomPages from "./ModifyCustomPages";
import ModifyConfiguration from "./ModifyConfiguration";
import ModifyColorScheme from "./ModifyColorScheme";
import ModifySingleFile from "./ModifySingleFile";
import ModifyCommunityImages from "./ModifyCommunityImages";
import GlobalConfig from "../../utils/GlobalConfig";
import LoadingIndicator from "../../components/LoadingIndicator";
import {IFile} from "../../api/adminApiClient";
import useAdminApiClient from "../../data/adminApiSource";
import CommunityInfoConfig from "../communities/CommunityInfoConfig";
import {IKovaCommunity, IKovaModel} from "../../api/generalApiClient";
import useGeneralApiClient from "../../data/generalApiSource";
import useToken from "../../hooks/useToken";
import {CustomViewConfigurations} from "../customviews/CustomViewConfigurations";

interface IBrandingProps {
  errorHandler: (error:Error) => void
}

export default function Branding(props: IBrandingProps) {
  const theme = useTheme();
  const adminApiSource = useAdminApiClient();
  const {adminToken} = useToken();
  const [isDirty, setIsDirty] = useState(true);
  const [files, setFiles] = useState<IFile[] | null>(null);
  const [models, setModels] = useState<IKovaModel[] | undefined>(undefined);
  const [communities, setCommunities] = useState<IKovaCommunity[] | undefined>(undefined);
  const generalApiSource = useGeneralApiClient();
  const {errorHandler} = props;

  function loadFiles() {
    adminApiSource.fetchFiles().then(fetchedFiles => {
      setFiles(fetchedFiles);
    }).catch(error => {
      errorHandler(error);
    }).finally(() => {
      setIsDirty(false);
    })
  }
  function loadCommunities() {
    generalApiSource.getCommunities(adminToken!).then(communities => {
      //sort and add not selected
      let sortedCommunites = [...communities.result].sort((c1, c2) => c1.Name > c2.Name ? 1 : -1);
      let notSelectedCommunity = {CommunityID: "", CommunityRID: 0, BUnitRID : 0, Name: "Not Selected"} as IKovaCommunity;
      sortedCommunites.unshift(notSelectedCommunity);

      setCommunities(sortedCommunites);
    }).catch(error => {
      errorHandler(error);
    });
  }

  function loadModels() {
    generalApiSource.getModels(adminToken!).then(models => {
      //sort and add not selected
      let sortedModels = [...models.result].sort((c1, c2) => c1.Name > c2.Name ? 1 : -1);
      let notSelectedModel = {ModelID: "", ModelRID : 0, Name: "Not Selected"} as IKovaModel;
      sortedModels.unshift(notSelectedModel);

      setModels(sortedModels);
    }).catch(error => {
      errorHandler(error);
    });
  }

  function onFileLoad(id:string) {
    setIsDirty(true);
  }

  useEffect(() => {
    loadFiles();
    loadModels();
    loadCommunities();
  }, [isDirty]);

  const defaultLogo = files?.find((file) => file.fileKey === 'defaultLogo');
  const loginImage = files?.find((file) => file.fileKey === 'loginImage');
  const dictionary = files?.find((file) => file.fileKey === 'dictionary');
  const communityLogos = files?.filter((file) => file.fileKey === 'communityLogo');
  const footerImageOnes = files?.filter((file) => file.fileKey === 'footerImageOne');
  const footerImageTwos = files?.filter((file) => file.fileKey === 'footerImageTwo');

  const isLoadingIndicatorActive = isDirty === undefined;

  return (
    <>
      <Card
        className={styles.communityCard}
        sx={{
          width: "100%",
          height: "100%"
        }}
      >
        <CardHeader
          subheader=""
          subheaderTypographyProps={{ variant: 'body2' }}
          sx={{ marginBottom: 2, padding: 0 }}
          title="Branding"
          titleTypographyProps={{ variant: 'h5', sx: { textTransform: 'capitalize' }}}
        />
        { files && (
          <Box sx={{position:'relative'}}>
            <Box mb={2}>
              <ModifySingleFile title={'Company Logo'} filetype={'image'} fileKey={'defaultLogo'} sourceFileInfo={defaultLogo} onFileLoad={onFileLoad} />
            </Box>
            <Box mb={2}>
              <ModifySingleFile title={'Customer Portal Login Page Image'} filetype={'image'} fileKey={'loginImage'} sourceFileInfo={loginImage} onFileLoad={onFileLoad} />
            </Box>
            <Box mb={2}>
              <ModifySingleFile title={'Custom Dictionary'} filetype={'json'} fileKey={'dictionary'} sourceFileInfo={dictionary} onFileLoad={onFileLoad}
                                additionalViewComponent={<a className={styles.dictionary} href={`${GlobalConfig.instance().CUSTOMER_PORTAL_URL}/dictionary.json`} target='_blank'>Standard Dictionary</a>}
              />
            </Box>
            <Box mb={2}>
              <ModifyCommunityImages title={'Community Logo'} fileKey={'communityLogo'} sourceFileInfos={communityLogos} onFileLoad={onFileLoad}  />
            </Box>
            <Box mb={2}>
              <ModifyCommunityImages title={'Footer Image One'} fileKey={'footerImageOne'} sourceFileInfos={footerImageOnes} onFileLoad={onFileLoad}  />
            </Box>
            <Box mb={2}>
              <ModifyCommunityImages title={'Footer Image Two'} fileKey={'footerImageTwo'} sourceFileInfos={footerImageTwos} onFileLoad={onFileLoad}  />
            </Box>
            <Box mb={2}>
              <ModifyCustomPages />
            </Box>
            <Box mb={2}>
              <ModifyConfiguration />
            </Box>
            <Box mb={2}>
              <ModifyColorScheme />
            </Box>
            <Box mb={2}>
              <CommunityInfoConfig errorHandler={errorHandler} communities={communities} models={models} />
            </Box>
            <Box mb={2} paddingBottom={10}>
              <CustomViewConfigurations typeOfCustomViews={"Warranty Service Requests"} errorHandler={errorHandler}/>
            </Box>
          </Box>
        )}
      </Card>

      <Backdrop
        open={isLoadingIndicatorActive}
        sx={{ bgcolor: 'rgba(0, 0, 0, 0.6)', color: '#fff', position: 'absolute' }}
      >
        <LoadingIndicator
          color="#fff"
          isLoading={isLoadingIndicatorActive}
          message='Loading branding...'
        />
      </Backdrop>
    </>
  );
}
