import {useState} from 'react';

function useInput(
  initialValue: any,
) {
  const [value, setValue] = useState(initialValue);

  return {
    onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
      const target = event.currentTarget;
      if (target.type == 'checkbox') {
        const checkbox = target as HTMLInputElement;
        setValue(checkbox.checked.toString());
      } else {
        setValue(target.value);
      }
    },
    reset: (value?: string) => setValue(value || ''),
    setValue,
    value
  }
}

export default useInput;
