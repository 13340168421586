export interface IColorSchemeMetadata {
  textName: string,
  info: string,
  fieldId: string
}

export const colorSchemeMetadata: IColorSchemeMetadata[] = [
  {
    textName: 'Community RID',
    info: 'Community RID (0 = default)',
    fieldId: 'communityRID'
  },
  {
    textName: 'Background Color 1',
    info: 'Primary menu background',
    fieldId: 'primaryMenuBg'
  },
  {
    textName: 'Background Color 2',
    info: 'Primary menu background highlighted ',
    fieldId: 'primaryMenuHighLightBg'
  },
  {
    textName: 'Nav Menu Selected Font Color',
    info: 'Primary menu Font Color',
    fieldId: 'primaryMenuFontColor'
  },
  {
    textName: 'Settings background 1',
    info: 'Settings menu Background',
    fieldId: 'settingsMenuBg'
  },
  {
    textName: 'Settings background 2',
    info: 'Settings menu background highlighted',
    fieldId: 'settingsMenuHighlightBg'
  },
  {
    textName: 'title bar Background',
    info: 'Title bar background',
    fieldId: 'titleBarBgColor'
  },
  {
    textName: 'Title Bar Font Color',
    info: 'Title Bar Font Color',
    fieldId: 'titleBarFontColor'
  },
  {
    textName: 'Title Bar Font Size',
    info: 'Title Bar Font Size',
    fieldId: 'titleBarFontSize'
  },
  {
    textName: 'node in progress',
    info: 'Node: in progress',
    fieldId: 'nodeInProgress'
  },
  {
    textName: 'node completed',
    info: 'Node: completed',
    fieldId: 'nodeCompleted'
  },
  {
    textName: 'node not completed',
    info: 'Node: not completed',
    fieldId: 'nodeNotCompleted'
  },
  {
    textName: 'node selected',
    info: 'Node: node selected',
    fieldId: 'nodeSelected'
  },
  {
    textName: 'Accent Color A',
    info: 'Background',
    fieldId: 'accentColorA'
  },
  {
    textName: 'Accent Color B',
    info: 'Background',
    fieldId: 'accentColorB'
  },
  {
    textName: 'Accent Color C',
    info: 'Background',
    fieldId: 'accentColorC'
  },
];
