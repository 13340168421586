export interface IGlobalConfig {
  BASE_URL: string;
  CUSTOMER_PORTAL_URL: string;
  MSAL_ENVIRONMENT: string;
  MSAL_CLIENT_ID: string;
  MSAL_AUTHORITY: string;
  API_SCOPE: string;
  KOVA_API_URL: string;
}

class GlobalConfig {
  private config : IGlobalConfig;
  private isInitialized : boolean;

  constructor() {
    this.config = <IGlobalConfig>{};
    this.isInitialized = false;
  }

  setConfig(config : IGlobalConfig) {
    this.config = config;
    this.isInitialized = true;
  }

  initialized(): boolean {
    return this.isInitialized;
  }

  instance(): Readonly<IGlobalConfig> {
    if (!this.initialized()) {
      throw new Error("GlobalConfig not initialized!")
    }

    return this.config;
  }
}

const configInstance = new GlobalConfig();

export default configInstance;
