import React, {useEffect, useState} from 'react';
import {BimaireAdMultitenantClient} from "@bimaire/kova-api-client";
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import useToken from "./hooks/useToken";
import Login from './pages/login/Login';
import {Dashboard} from "./pages/dashboard/Dashboard";
import {Communities} from "./pages/communities/Communities";
import {Messages} from "./pages/messages/Messages";
import {Box, CssBaseline, ThemeProvider, useMediaQuery, useTheme} from "@mui/material";
import GlobalConfig from "./utils/GlobalConfig";
import SideNav from "./components/SideNav";
import theme from "./theme/theme";
import Copyright from "./components/Copyright";
import Header from "./components/Header";
import DefaultLogo from "./img/logo.png";
import {CommunityFromRoute} from "./pages/communities/Community";
import Branding from "./pages/branding/Branding";
import {AdminApiClientContext} from './data/adminApiSource';
import AdminApiClient from './api/adminApiClient';
import GeneralApiClient from './api/generalApiClient';
import {GeneralApiClientContext} from './data/generalApiSource';
import {SessionExpiredError} from "./api/apiClient";
import {Models} from "./pages/models/Models";
import useAdminApiClient from "./data/adminApiSource";

interface IAppProps {
  adClient : BimaireAdMultitenantClient,
  companyLogo: string
}

const drawerWidth = 185;

function ThemedApp({adClient, companyLogo} : IAppProps) {
  const {adminToken, setAdminToken} = useToken();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const adminApiSource = useAdminApiClient();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const onLogout = () => {
    setAdminToken(null);
    adminApiSource.logout();
  }

  const errorHandler = (error: Error) => {
    alert(error.message);

    if (error instanceof SessionExpiredError) {
      onLogout();
    }
  }

  if (!adminToken) {
    return (
      <Login handleLogin={setAdminToken} adClient={adClient} companyLogo={companyLogo} />
    );
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Box sx={{ display: 'flex', minHeight: '100vh' }}>
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          >
            {isSmUp ? null : (
              <SideNav
                drawerProps={{
                  PaperProps:{ style: { width: drawerWidth } },
                  variant:"temporary",
                  open:mobileOpen,
                  onClose:handleDrawerToggle
                }}
                handleDrawerToggle={handleDrawerToggle}
                companyLogo={companyLogo}
              />
            )}
            <SideNav
              drawerProps={{
                PaperProps: {style: {width: drawerWidth}},
                sx: {display: {sm: 'block', xs: 'none'}}
              }}
              handleDrawerToggle={handleDrawerToggle}
              companyLogo={companyLogo}
            />
          </Box>
          <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <Header onDrawerToggle={handleDrawerToggle} onLogout={onLogout} />
            <Box component="main" sx={{ flex: 1, pt: 2, px: 2, bgcolor: '#eaeff1' }}>
              <Routes>
                <Route index element={<Dashboard/>}/>
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="communities" element={<Communities errorHandler={errorHandler}/>} />
                <Route path="communities/:communityRID" element={<CommunityFromRoute errorHandler={errorHandler}/>} />
                <Route path="messages/*" element={<Messages errorHandler={errorHandler}/>} />
                <Route path="branding/*" element={<Branding errorHandler={errorHandler}/>} />
                <Route path="models" element={<Models errorHandler={errorHandler} />} />
                <Route path="*" element={<Dashboard />} />
              </Routes>
            </Box>
            <Box component="footer" sx={{ p:1, bgcolor: '#eaeff1' }}>
              <Copyright />
            </Box>
          </Box>
        </Box>
      </BrowserRouter>
    </div>
  );
}

function AppConfigLoader() {
  const [configLoaded, setConfigLoaded] = useState<boolean | undefined>(false);
  const [companyLogo, setCompanyLogo] = useState<string | undefined>(undefined);

  useEffect(() => {
    fetch('/envconfig.json').then(res => res.json().then(resJson => {
      GlobalConfig.setConfig(resJson);

      setConfigLoaded(true);
    }));
  }, []);

  useEffect(() => {
    if (configLoaded  && GlobalConfig.initialized()) {
      const adminApiClient = new AdminApiClient();

      adminApiClient.fetchFiles().then(files => {
        const logo = files.find((file) => file.fileKey === 'defaultLogo');
        if (logo) {
          try {
            setCompanyLogo(logo.s3Path);
          } catch (err) {
            console.error('Error - failed to fetch file blob for company logo!', err);
          }
        }
        else {
          setCompanyLogo(DefaultLogo);
        }
      });
    }
  }, [configLoaded, GlobalConfig.initialized()]);

  if (!configLoaded || !GlobalConfig.initialized() || !companyLogo) return null;

  const adClient = new BimaireAdMultitenantClient({
    apiScope: GlobalConfig.instance().API_SCOPE,
    clientId: GlobalConfig.instance().MSAL_CLIENT_ID
  });

  const adminApiClient = new AdminApiClient();
  const generalApiClient = new GeneralApiClient();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AdminApiClientContext.Provider value={adminApiClient}>
        <GeneralApiClientContext.Provider value={generalApiClient}>
          <ThemedApp adClient={adClient} companyLogo={companyLogo} />
        </GeneralApiClientContext.Provider>
      </AdminApiClientContext.Provider>
    </ThemeProvider>
  );
}
function App() {
  return (
    <AppConfigLoader />
  )
}
export default App;
