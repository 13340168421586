import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {Fab} from "@mui/material";
import {Delete, Edit, Refresh} from "@mui/icons-material";
import { ICommunityInfo } from "../../api/manifest";
import { IKovaCommunity, IKovaModel } from "../../api/generalApiClient";

function getCommunityLabel(kovacommunities: IKovaCommunity[], id: string) {
  const idParams = id.split('_');
  const community = kovacommunities.find(c => c.BUnitRID === +idParams[0]);
  return community ? community.CommunityID + "(" + community.CommunityRID + ")" : "N/A";
}
function getModelLabel(models: IKovaModel[], id: string) {
  const idParams = id.split('_');
  const model = models.find(c => c.ModelRID === +idParams[2]);
  return model ? model.ModelID + "(" + model.ModelRID + ")" : "N/A";
}

interface ICommunityInfoGridProps {
  communities: ICommunityInfo[],
  refreshOptionData: (bunitRid: number, modelRid: number, optSels: string[]) => void,
  handleEdit: (id: string) => void,
  handleDelete: (bunitRid: number, modelRid: number) => void,
  kovacommunities: IKovaCommunity[],
  models: IKovaModel[]
}

export function CommunityInfoGrid(props: ICommunityInfoGridProps) {
  const {communities, refreshOptionData, handleEdit, handleDelete, kovacommunities, models} = props;

  const columns: GridColDef[] = [
    {field: 'name', flex: 2, headerName: 'Name'},
    {field: 'id', flex: 2, headerName: 'Community/Model ID(RID)', renderCell: (params: any) => (
      <span>{getCommunityLabel(kovacommunities, params.value as string) + " / " + getModelLabel(models, params.value as string)}</span>
    )},
    {field: 'theme', flex: 1, headerName: 'Theme'},
    {field: 'status', flex: 1, headerName: 'Status'},
    {field: 'fetchedAt', flex: 1, headerName: 'Last Updated', renderCell: (params: any) => (
      <span>{+params.value > 0 ? new Date(+params.value).toLocaleString() : 'N/A'}</span>
    )  
    },
    {
      field: 'Refresh',
      flex: 0,
      headerName: 'Refresh Data',
      renderCell: (params: any) => (
        <Fab color="primary" size={"small"} aria-label="go" sx={{
          ":hover":{
            backgroundColor:"primary.light"
          }
        }}>
          <Refresh />
        </Fab>
      )
    },
    {
      field: 'Edit',
      flex: 0,
      headerName: 'Edit',
      renderCell: (params: any) => (
        <Fab color="primary" size={"small"} aria-label="go" sx={{
          ":hover":{
            backgroundColor:"primary.light"
          }
        }}>
          <Edit />
        </Fab>
      )
    },
    {
      field: 'Delete',
      flex: 0,
      headerName: 'Delete',
      renderCell: (params: any) => (
        <Fab color="primary" size={"small"} aria-label="go" sx={{
          ":hover":{
            backgroundColor:"primary.light"
          }
        }}>
          <Delete />
        </Fab>
      )
    }
  ];

  return <>
          <DataGrid
            columns={columns}
            onCellClick={(params => {
              if (params.colDef.field === "Refresh") {
                const communityInfo = params.row as ICommunityInfo;
                const id = communityInfo.id;
                const rids = id.split('_');
                const optSels = communityInfo.optSels ? communityInfo.optSels.map(o => o.id) : [];
                refreshOptionData(+rids[0], +rids[2], optSels);
              } else if (params.colDef.field === "Edit") {
                const communityInfo = params.row as ICommunityInfo;
                const id = communityInfo.id;
                handleEdit(id);
              } else if (params.colDef.field === "Delete") {
                const communityInfo = params.row as ICommunityInfo;
                const id = communityInfo.id;
                const rids = id.split('_');
                handleDelete(+rids[0], +rids[2]);
              }
            })}
            getRowId={(row:ICommunityInfo) => row.id}
            rows={communities}
            sx={{ border: 'none' }}
            disableRowSelectionOnClick={true}
            pagination
            autoHeight
          />
    </>
  
}
