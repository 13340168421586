import React, {useEffect, useState} from "react";
import useAdminToken from "../../hooks/useToken";
import useAdminApiClient from "../../data/adminApiSource";
import {Backdrop, Box, Card, CardHeader, Fab, useMediaQuery, useTheme} from "@mui/material";
import styles from "./Messages.module.css";
import LoadingIndicator from "../../components/LoadingIndicator";
import {MessagesGrid} from "./MessagesGrid";
import {MessagesList} from "./MessagesList";
import {INotification} from "../../api/adminApiClient";
import {MessageForm} from "./MessageForm";
import {Add} from "@mui/icons-material";

interface IMessagesProps {
    errorHandler: (error: Error) => void
}

export function Messages(props: IMessagesProps) {
  const {adminToken} = useAdminToken();
  const adminApiSource = useAdminApiClient();
  const [isDirty, setIsDirty] = useState<boolean>(true);
  const [messages, setMessages] = useState<INotification[]>();
  const [selectedMessage, setSelectedMessage] = useState<INotification | null>();
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));


  function onAdd() {
    let message = {} as INotification;
    setSelectedMessage(message);
  }

  function openEdit(message: INotification) {
    setSelectedMessage(message);
  }

  function onClose() {
    setSelectedMessage(null);
  }

  function onSave(message: INotification) {
    if (message.id) {
      adminApiSource.editNotification(adminToken!, message.id, message).then(() => {
        setSelectedMessage(null);
      }).catch(error => {
        props.errorHandler(error);
      }).finally(() => {
        setIsDirty(true);
      })
    }
    else {
      adminApiSource.addNotification(adminToken!, message).then(() => {
        setSelectedMessage(null);
      }).catch(error => {
        props.errorHandler(error);
      }).finally(() => {
        setIsDirty(true);
      })
    }
  }

  function onDelete(id: string) {
    if (id) {
      adminApiSource.deleteNotification(adminToken!, id).then(() => {
        setSelectedMessage(null);
      }).catch(e => {
        alert(e.message);
      }).finally(() => {
        setIsDirty(true);
      })
    }
  }

  useEffect(() => {
    document.title = 'Messages';
  });

  function loadMessages() {
    adminApiSource.fetchNotifications(adminToken!).then(adminMessages => {
      setMessages(adminMessages);
    }).catch(error => {
      props.errorHandler(error);
    }).finally(() => {
      setIsDirty(false);
    })
  }

  useEffect(() => {
    loadMessages();
  }, [isDirty]);

  const isLoadingIndicatorActive = isDirty === undefined;

  return (
    <>
      <Card
        className={styles.messagesCard}
        sx={{
          width: "100%",
          height: "100%"
        }}
      >
        <CardHeader
          subheader=""
          subheaderTypographyProps={{variant: 'body2'}}
          sx={{marginBottom: 2, padding: 0}}
          title="All messages"
          titleTypographyProps={{variant: 'h5', sx: {textTransform: 'capitalize'}}}
        />
        {isSmUp ?
          (<MessagesGrid messages={messages} openEdit={openEdit}/>) :
          (<MessagesList messages={messages} openEdit={openEdit}/>)}
        {messages && (
          <Box sx={{position: isSmUp ? 'relative' : 'fixed', bottom: isSmUp ? '' : '45px', right: isSmUp ? '' : '25px' }} pt={2}>
            <Fab color="primary" aria-label="add" size={"small"} onClick={onAdd}>
              <Add/>
            </Fab>
          </Box>
        )}
      </Card>
      {selectedMessage && (
        <MessageForm selectedMessage={selectedMessage} onClose={onClose} onSave={onSave} onDelete={onDelete} />
      )}
      <Backdrop
        open={isLoadingIndicatorActive}
        sx={{bgcolor: 'rgba(0, 0, 0, 0.6)', color: '#fff', position: 'absolute'}}
      >
        <LoadingIndicator
          color="#fff"
          isLoading={isLoadingIndicatorActive}
          message='Loading messages...'
        />
      </Backdrop>
    </>
  );
}
