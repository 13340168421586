import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  TextField,
  useMediaQuery,
  Switch
} from '@mui/material';
import dayjs, {Dayjs} from 'dayjs';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {INotification} from "../../api/adminApiClient";
import {useState} from "react";
import styles from './MessageForm.module.css'
import theme from "../../theme/theme";
import useInput from "../../hooks/useInput";

interface IMessageFormProps {
  selectedMessage: INotification;
  onClose: () => void;
  onSave: (message:INotification) => void;
  onDelete: (id:string) => void;
}

export function MessageForm (props: IMessageFormProps) {
  const {selectedMessage, onClose, onSave, onDelete} = props;
  const [date, setDate] = useState<Dayjs | null>(dayjs(selectedMessage.date));
  const [hideDate, setHideDate] = useState<boolean>(selectedMessage.hideDate);
  const [type, setType] = useState<string>(selectedMessage.type);
  const {onChange: onHeaderChange, value: header} = useInput(selectedMessage.header);
  const {onChange: onTextChange, value: text} = useInput(selectedMessage.text);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    let ok = true;
    if (
      selectedMessage.date && !dayjs(selectedMessage.date).isSame(date) ||
      selectedMessage.type !== type ||
      selectedMessage.header !== header ||
      selectedMessage.text !== text ||
      selectedMessage.hideDate !== hideDate
    ) {
      ok = window.confirm("Changes found! Are you sure you want to close this window?");
    }
    if (ok) {
      onClose();
    }
  };

  const handleSave = () => {
    let saveMessage = {
      id: selectedMessage.id,
      date: date?.format('YYYY-MM-DD'),
      header: header,
      text: text,
      type: type,
      hideDate: hideDate
    } as INotification;
    onSave(saveMessage);
  };

  const handleDelete = () => {
    const ok = window.confirm("Deleting this notification! Are you really sure you want to delete?");
    if (ok) {
      onDelete(selectedMessage.id!);
    }
  };

  return (
    <Dialog open={true} onClose={handleClose} fullScreen={fullScreen} aria-labelledby="dialog-title">
      <DialogTitle id="dialog-title">Edit {type} message</DialogTitle>
      <DialogContent>
        <Paper className={styles.paper}>
          <FormControl id="messageform">
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date"
                    value={date}
                    onChange={(newValue) => {
                      setDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel label="Hide date from users"
                    control={<Switch name="showdate"
                      checked={hideDate}
                      onChange={(event) => {
                        setHideDate(event.target.checked);
                      }}
                    />}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  select
                  id="type"
                  name="type"
                  label="Type"
                  fullWidth
                  defaultValue={type}
                  onChange={(event) => {
                    setType(event.target.value);
                  }}
                >
                  <MenuItem key={'login'} value={'login'}>Login</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="header"
                  name="header"
                  label="Title"
                  fullWidth
                  defaultValue={header}
                  onChange={onHeaderChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="text"
                  name="text"
                  label="Text"
                  fullWidth
                  defaultValue={text}
                  multiline={true}
                  onChange={onTextChange}
                />
              </Grid>
            </Grid>
          </FormControl>
        </Paper>
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-between", marginBottom:'6px' }}>
        <Box>
          {selectedMessage.id && (
            <Button onClick={handleDelete}>
              Delete
            </Button>
          )}
        </Box>
        <Box>
          <Button autoFocus onClick={handleClose}>
            Back
          </Button>
          <Button onClick={handleSave} autoFocus>
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
