import * as React from 'react';
import Divider from '@mui/material/Divider';
import Drawer, {DrawerProps} from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import SideNavItem from "./SideNavItem";
import {ColorLens, HolidayVillage, Home, MapsHomeWork, Notifications as NotificationsIcon} from "@mui/icons-material";
import styles from "./SideNav.module.css";

export default function SideNav(props: {drawerProps: DrawerProps, handleDrawerToggle: () => void, companyLogo: string }) {
  const { ...other } = props.drawerProps;
  const { handleDrawerToggle, companyLogo } = props;

  return (
    <Drawer variant="permanent" {...other} elevation={1} onClick={handleDrawerToggle}>
      <Box sx={{
        backgroundColor: "primary.main",
        height:'100vh'
      }} alignItems={'center'} color="primary" >
        <Box display={'flex'} justifyContent={'center'} p={'20px'}>
          <div className={styles.logoWrapper}>
            <img src={companyLogo} alt={'Logo'} className={styles.logo} />
          </div>
        </Box>
        <Divider />
        <SideNavItem path={'/'} icon={<Home/>} linkText={'Home'} />
        <SideNavItem path={'/communities'} icon={<MapsHomeWork/>} linkText={'Communities'} />
        <SideNavItem path={'/models'} icon={<HolidayVillage/>} linkText={'Models'} />
        <SideNavItem path={'/messages'} icon={<NotificationsIcon/>} linkText={'Messages'} />
        <SideNavItem path={'/branding'} icon={<ColorLens/>} linkText={'Branding'} />
      </Box>
    </Drawer>
  );
}
