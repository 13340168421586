import {AdminToken} from "../hooks/useToken";

export class SessionExpiredError extends Error {

}

export interface IApiClient {
  get(apiPath: string, apiToken?: AdminToken): Promise<Response>;
  save(apiPath: string, apiToken: AdminToken | undefined, postBody: string | FormData, method?: string, contentType?:string): Promise<Response>;
  delete(apiPath: string, apiToken: AdminToken): Promise<Response>;
  handleNonOkReponse<ReturnType>(response: Response): Promise<ReturnType>;
}

class ApiClient implements IApiClient {

  constructor(private readonly adminApiUrl:string) {
  }

  private appendBearerToken(apiToken: AdminToken | undefined, headers: {}) {
    if (apiToken) {
      const authHeader = {
        Authorization: `Bearer ${apiToken.token}`
      };

      headers = {...headers, ...authHeader};
    }
    return headers;
  }

  async get(apiPath: string, apiToken?: AdminToken): Promise<Response> {
    let headers = {
    };

    headers = this.appendBearerToken(apiToken, headers);

    return await fetch(`${this.adminApiUrl}${apiPath}`, {
      method: 'GET',
      credentials:"include",
      headers: headers
    })
  }

  async save(apiPath: string, apiToken: AdminToken | undefined, postBody: string | FormData, method?: string, contentType?:string, includeCredentials: boolean = true): Promise<Response> {
    if (!method) {
      method = 'POST';
    }

    let headers = {};
    if (!contentType) {
      contentType = 'application/json';
    }
    if (contentType && contentType !== 'multipart/form-data') {
      headers = {...headers, ...{
          'Content-Type': contentType
        }}
    }

    if(apiToken) {
      headers = {...headers, ...{'X-XSRF': apiToken.token!}};
    }

    return await fetch(`${this.adminApiUrl}${apiPath}`, {
      credentials: includeCredentials ? "include" : "same-origin",
      method: method,
      mode: 'cors',
      cache: 'no-cache',
      headers: headers,
      body: postBody,
    })
  }

  async delete(apiPath: string, apiToken: AdminToken): Promise<Response> {

    const headers = {'X-XSRF': apiToken.token!};

    return await fetch(`${this.adminApiUrl}${apiPath}`, {
      method: 'DELETE',
      credentials:'include',
      headers: headers
    })
  }


  async handleNonOkReponse<ReturnType>(response: Response): Promise<ReturnType>
  {
    if (response.status == 400) {
      const body = await response.text();
      if (body) {
        throw new Error(`${body}`)
      }
    }
    else if (response.status == 401)
    {
      const tokenExpired = await response.text() === 'jwt expired';
      if (tokenExpired) {
        throw new SessionExpiredError('Session expired. Logging out!');
      }
    }

    throw new Error(`Received an unknown error from server: ${response.statusText}`);
  }
}


export default ApiClient;
