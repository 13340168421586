import React, {useState} from 'react';
import {BimaireAdMultitenantClient} from "@bimaire/kova-api-client";
import {AdminToken} from "../../hooks/useToken";
import {Alert, AlertTitle, Avatar, Box, Button, Grid, Paper, TextField, Typography} from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Copyright from "../../components/Copyright";
import GlobalConfig from "../../utils/GlobalConfig";
import {MsalProvider, useMsal} from '@azure/msal-react';
import useAdminApiClient from "../../data/adminApiSource";
import styles from "./Login.module.css"

export interface ILoginProps {
  handleLogin: (token:AdminToken | null)=> void,
  adClient: BimaireAdMultitenantClient;
  companyLogo: string
}
interface ILoginMSALWrapperProps {
  handleLogin: (token:AdminToken | null)=> void,
  adClient : BimaireAdMultitenantClient,
  companyLogo: string
}

function Login(props: ILoginProps) {
  const msal = useMsal();
  const adminApiSource = useAdminApiClient();
  const [error, setError] = useState<string | null>(null);


  async function handleSSOLogin() {
    try {
      let authority = GlobalConfig.instance().MSAL_AUTHORITY;
      const kovaToken = await props.adClient.getTokenForKovaAuth(msal, undefined, authority);
      let loginResult = await adminApiSource.loginSSO(GlobalConfig.instance().MSAL_ENVIRONMENT, kovaToken);
      let adminToken = new AdminToken();
      adminToken.token = loginResult.token;
      adminToken.kovaToken = kovaToken;
      props.handleLogin(adminToken);
    } catch (e) {
      const error = e as Error;
      setError(error.message);
    }
  }

  return(
    <Grid container component="main" sx={{ height: '100vh', flexDirection:'row', justifyContent: 'center' }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        className={styles.logoWrapper}
      >
        <img src={props.companyLogo} alt={'Logo'} className={styles.logo} />
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}
      >

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >&nbsp;</Box>
        <Box
          sx={{
            my: 2,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1, width:'100%' }}>
            {error && (
              <Alert severity="error" sx={{textTransform: 'capitalize', width:'100%' }}>
                <AlertTitle>Login Failed</AlertTitle>
                {error}
              </Alert>
            )}
            <Button
              fullWidth
              onClick={() => handleSSOLogin()}
              sx={{ mt: 3, mb: 2 }}
              variant="contained"
            >
              LOGIN USING SINGLE SIGN-ON
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5, mb: 2 }} />
      </Grid>
    </Grid>
  )
}

export default function LoginMSALWrapper({ handleLogin, adClient,companyLogo } : ILoginMSALWrapperProps) {
  return (
    <MsalProvider instance={adClient.getMsalPublicClientApplication()}>
      <Login handleLogin={handleLogin} adClient={adClient} companyLogo={companyLogo}/>
    </MsalProvider>
  )
}
