import React, {useEffect, useState} from "react";
import styles from "../Branding.module.css";
import {ColorSchemeForm} from "./ColorSchemeForm";
import {Box, Button, FormControl, InputLabel, MenuItem, TextField} from "@mui/material";
import Select from '@mui/material/Select';
import {Add, Remove} from "@mui/icons-material";
import useToken from "../../../hooks/useToken";
import {IColorScheme} from "../../../api/adminApiClient";
import useAdminApiClient from "../../../data/adminApiSource";

export default function ColorSchemeGrid(props:{setIsLoadingIndicatorActive:(value: boolean)=>void}) {
  const {setIsLoadingIndicatorActive} = props;
  const {adminToken} = useToken();
  const adminApiSource = useAdminApiClient();
  const [colorSchemes, setColorSchemes] = useState<IColorScheme[]>();
  const [selectedCommunity, setSelectedCommunity] = useState<number>();

  function addNewCommunity(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    try {
      setIsLoadingIndicatorActive(true);

      let cloneCommunity = selectedCommunity ? selectedCommunity : 0;
      const defaultColorScheme = colorSchemes?.find(colorScheme => colorScheme.communityRID == cloneCommunity);
      let newColorScheme = {...defaultColorScheme} as IColorScheme;

      const formData = new FormData(e.currentTarget);
      newColorScheme.id = undefined;
      newColorScheme.communityRID = Number(formData.get('communityrid'));

      adminApiSource.createColorScheme(adminToken!, newColorScheme).then(() => {
        loadColorSchemes();
        setSelectedCommunity(newColorScheme.communityRID);
        setIsLoadingIndicatorActive(false);
      });
    } catch (err) {
      alert('Add failed!');
      setIsLoadingIndicatorActive(false);
    }
  }

  function onEditCommunity(updatedColorScheme: IColorScheme) {
    try {
      setIsLoadingIndicatorActive(true);
      adminApiSource.updateColorScheme(adminToken!, updatedColorScheme.id, updatedColorScheme).then(() => {
        loadColorSchemes();
        setIsLoadingIndicatorActive(false);
      })
    } catch (err) {
      alert('Load failed!');
      setIsLoadingIndicatorActive(false);
    }
  }

  function onRemoveCommunity() {
    try {
      if (selectedCommunity === 0) {
        alert('Cannot delete the default colorscheme!');
        return;
      }
      if (!window.confirm('Are you sure you want to remove this color scheme?')) {
        return;
      }

      if (selectedCommunity) {
        const colorScheme = colorSchemes?.find(colorScheme => colorScheme.communityRID === selectedCommunity);
        if (!colorScheme) {
          alert('Could not find the color scheme!');
        } else {
          setIsLoadingIndicatorActive(true);
          adminApiSource.deleteColorScheme(adminToken!, colorScheme.id).then(() => {
            loadColorSchemes();
            setSelectedCommunity(undefined);
            setIsLoadingIndicatorActive(false);
          });
        }
      } else {
        alert('Please select a color scheme to delete!')
      }
    } catch (err) {
      alert('Delete failed!');
      setIsLoadingIndicatorActive(false);
    }
  }

  function loadColorSchemes() {
    try {
      setIsLoadingIndicatorActive(true);
      adminApiSource.fetchColorSchemes(adminToken!).then(colors => {
        setColorSchemes(colors.sort((a, b)=> a.communityRID - b.communityRID));
        setIsLoadingIndicatorActive(false);
      });
    } catch (err) {
      alert('Load failed!');
      setIsLoadingIndicatorActive(false);
    }
  }

  useEffect(() => {
    try {
      loadColorSchemes();
    } catch (err) {
      alert('Could not get colors!');
    }
  }, [!colorSchemes]);

  const selectedColorScheme = colorSchemes?.find(colorScheme => colorScheme.communityRID === selectedCommunity);

  return (
    <div className={styles.customPagesTable}>
      <Box>
        <Box margin={4} display={'flex'}>
          <FormControl>
            <InputLabel id="selectCommunityInput">Select community</InputLabel>
            <Select
              title="Select community"
              displayEmpty={false}
              placeholder="Select community"
              onChange={(e) => {
                setSelectedCommunity(Number(e.target.value))
              }}
              label="Select community"
              value={selectedCommunity}
              sx={{ minWidth: 200 }}
            >
            {colorSchemes && colorSchemes.map( colorScheme => {
                return (
                  <MenuItem value={colorScheme.communityRID}>
                    {colorScheme.communityRID}
                  </MenuItem>
                )
              }
            )}
            </Select>
          </FormControl>
          <Box ml={4}>
            <form onSubmit={addNewCommunity}>
              <Box display={'flex'}>
                <TextField label="Add community" type="text" name="communityrid" />
                <Button
                  type="submit"
                  startIcon={<Add />}
                  sx={{ bgcolor: 'primary.light', marginLeft:'20px' }}
                  variant="contained"
                >
                  CLONE SELECTED COMMUNITY
                </Button>
              </Box>
            </form>
          </Box>
          <Button
            type="button"
            startIcon={<Remove />}
            sx={{ bgcolor: 'primary.light', marginLeft:'20px' }}
            variant="contained"
            onClick={onRemoveCommunity}
          >
            DELETE SELECTED COMMUNITY
          </Button>
        </Box>
      </Box>
      {
        !colorSchemes ? (<div>Loading Pages...</div>) :
          colorSchemes.length > 0
            ? selectedCommunity !== undefined && selectedColorScheme && (
              <ColorSchemeForm onEditCommunity={onEditCommunity} defaultColorScheme={colorSchemes.find(colorScheme => colorScheme.communityRID === 0)} colorScheme={selectedColorScheme} />
            )
            : (
              <div>No Configurations Found.</div>
            )
      }
    </div>
  );
}
