import {Link, useLocation} from 'react-router-dom';
import styles from './SideNavItem.module.css';


function SideNavItem(props: { path: string, icon: JSX.Element, linkText: string }) {
  const { path, icon, linkText } = props;
  const location = useLocation();

  return (
    <div className={`${styles.container} ${location.pathname === path ? styles.active : styles.inactive}`}>
      <Link
        className={`${styles.navLink}`}
        to={path}
      >
        {icon}
        <div>{linkText}</div>
      </Link>
    </div>
  );
}

export default SideNavItem;
