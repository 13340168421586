import { KovaUserClient } from "@bimaire/kova-api-client";
import {AdminToken} from "../hooks/useToken";
import ApiClient from "./apiClient";
import GlobalConfig from "../utils/GlobalConfig";
import {IGetKovaInfoResult} from "@bimaire/kova-api-client/build/kovaapi/kovaUserClient";

export interface ILoginResponse {
  token : string;
}

export interface ODataResponse<T> {
  values: T[];
}

export interface IData {
  type: string,
  data: number[]
}

export interface IFile {
  id:string | undefined,
  fileType:string,
  fileName:string,
  fileKey:string,
  communityRID:number,
  data: Blob,
  s3Path: string
}

export interface ICustomPage {
  id: string | undefined;
  linkKey: string;
  url: string;
  communityRID: number;
  text: string;
  enabled: number;
}

export interface IConfiguration {
  id: string | undefined;
  settingName: string;
  bunitRid: number;
  value: string;
  type: string;
  availableValues: string[];
}


export interface IColorScheme {
  id: string | undefined;
  communityRID: number;
  primaryMenuBg:string;
  primaryMenuHighLightBg:string;
  primaryMenuFontColor:string;
  settingsMenuBg:string;
  settingsMenuHighlightBg:string;
  titleBarBgColor:string;
  titleBarFontColor:string;
  titleBarFontSize:string;
  nodeInProgress:string;
  nodeCompleted:string;
  nodeNotCompleted:string;
  nodeSelected:string;
  accentColorA:string;
  accentColorB:string;
  accentColorC:string;
  calendarLegendText:string;
  calendarDayText:string;
  calendarWeekendText:string;
}

enum NotificationType {
  login
}

export interface INotification {
  id: string | undefined;
  date: string;
  header: string;
  text: string;
  type: keyof typeof NotificationType;
  hideDate: boolean;
}

export interface CustomViewConfiguration {
  id: string;
  name: string;
  header: string;
  description: string;
  show: boolean;
  sortOrder: number;
  type: string;
}

export interface KovaCustomView {
  customViewRID: number,
  name: string,
  roleRID: number
}

export interface IAdminApiClient {
  loginSSO(kovaEnvironmentId: string, kovaToken: string): Promise<ILoginResponse>;

  fetchFiles(): Promise<IFile[]>;
  updateFile(apiToken:AdminToken, id:string, formData:FormData): Promise<Response>;
  uploadNewFile(apiToken:AdminToken, formData:FormData): Promise<Response>;
  deleteFile(apiToken:AdminToken, id: IFile["id"]): Promise<Response>;

  fetchCustomPages(apiToken: AdminToken): Promise<ICustomPage[]>;
  addCustomPage(apiToken: AdminToken, customPage: ICustomPage): Promise<Response>;
  deleteCustomPage(apiToken: AdminToken, id: ICustomPage["id"]): Promise<Response>;
  editCustomPage(apiToken: AdminToken, id: ICustomPage["id"], customPage: ICustomPage): Promise<Response>;

  fetchConfigurations(apiToken: AdminToken):Promise<IConfiguration[]>;
  updateConfiguration(apiToken: AdminToken, id: IConfiguration["id"], configuration: IConfiguration):Promise<Response>;

  fetchColorSchemes(apiToken: AdminToken):Promise<IColorScheme[]>;
  createColorScheme(apiToken: AdminToken, colorScheme: IColorScheme):Promise<Response>;
  updateColorScheme(apiToken: AdminToken, id: IColorScheme["id"], colorScheme: IColorScheme):Promise<Response>;

  fetchNotifications(apiToken: AdminToken):Promise<INotification[]>;
  addNotification(apiToken: AdminToken, notification: { header: string; date: string; text: string; type: string; }):Promise<Response>;
  deleteNotification(apiToken: AdminToken, id: INotification["id"]):Promise<Response>;
  editNotification(apiToken: AdminToken, id: INotification["id"], notification: { header: string; date: string; text: string; }):Promise<Response>;

  getKovaCustomViews():Promise<KovaCustomView[]>;
  getCustomViewConfigurations(type?: string):Promise<CustomViewConfiguration[]>;
  createCustomViewConfiguration(apiToken: AdminToken, configuration: Partial<CustomViewConfiguration>):Promise<string>;
  updateCustomViewConfiguration(apiToken: AdminToken, configuration: Partial<CustomViewConfiguration>):Promise<Response>;
  deleteCustomViewConfiguration(apiToken: AdminToken, id: string):Promise<Response>;
}

class AdminApiClient implements IAdminApiClient {

  private kovaApiClient: ApiClient;

  constructor() {
    this.kovaApiClient = new ApiClient(`${GlobalConfig.instance().KOVA_API_URL}admincenter/`);
  }

  async loginSSO(kovaEnvironmentId: string, kovaToken: string): Promise<ILoginResponse> {

    let kovaApiUrl = GlobalConfig.instance().KOVA_API_URL.replace(`/api/${kovaEnvironmentId}/`, "");

    //KOVA_API_URL has been configured to reference magic cloudfront "route" /api/envName, need to provide KovaUserClient current url as KovaUserClient requires an actual URL
    if(kovaApiUrl == "")
    {
        kovaApiUrl = window.location.origin;
    }
    const kovaUserClient = new KovaUserClient({ kovaAuthEndpoint: kovaApiUrl });
    let kovaLoginResponse = await kovaUserClient.loginKovaUser(kovaToken, kovaEnvironmentId, ["AdminCenter-Access"]);
    if (kovaLoginResponse.success) {
      if (!kovaLoginResponse.userInfo || !kovaLoginResponse.userInfo.permissions || kovaLoginResponse.userInfo.permissions.length === 0) {
        throw new Error(`Insufficient Permissions`);
      }
      else {
        return {token: kovaLoginResponse.userInfo.csrfToken} as ILoginResponse;
      }
    } else {
      throw new Error(`Login failed`);
    }
  }

  async logout(){
    let kovaAuthenticationApiClient = new ApiClient(`${GlobalConfig.instance().KOVA_API_URL}authenticate/`);

    await kovaAuthenticationApiClient.save("logout", undefined, "", 'POST');
  }

  async fetchFiles(): Promise<IFile[]> {
    const response = await this.kovaApiClient.get('Files')

    if (response.ok)
    {
      let reponseJSON = await response.json() as IFile[];
      return reponseJSON;
    }
    return this.kovaApiClient.handleNonOkReponse<IFile[]>(response);
  }

  async updateFile(apiToken:AdminToken, id:string, formData:FormData): Promise<Response> {
    const response = await this.kovaApiClient.save(`Files/${id}`, apiToken, formData, 'PATCH', 'multipart/form-data');

    if (response.ok)
    {
      return response;
    }
    return this.kovaApiClient.handleNonOkReponse<Response>(response);
  }

  async uploadNewFile(apiToken:AdminToken, formData:FormData): Promise<Response> {
    const response = await this.kovaApiClient.save(`Files`, apiToken, formData, 'POST', 'multipart/form-data');

    if (response.ok)
    {
      return response;
    }
    return this.kovaApiClient.handleNonOkReponse<Response>(response);
  }

  async deleteFile(apiToken:AdminToken, id: IFile["id"]): Promise<Response> {
    const response = await this.kovaApiClient.delete(`Files/${id}`, apiToken);

    if (response.ok)
    {
      return response;
    }
    return this.kovaApiClient.handleNonOkReponse<Response>(response);
  }

  async fetchCustomPages(apiToken: AdminToken): Promise<ICustomPage[]> {
    const response = await this.kovaApiClient.get(`Links`, apiToken)

    if (response.ok)
    {
      let values = await response.json() as ICustomPage[];
      return values;
    }
    return this.kovaApiClient.handleNonOkReponse<ICustomPage[]>(response);
  }

  async addCustomPage(apiToken: AdminToken, customPage: ICustomPage): Promise<Response> {
    const body = JSON.stringify(customPage);
    const response = await this.kovaApiClient.save(`Links/`, apiToken, body, "POST", 'application/json')

    if (response.ok)
    {
      return response;
    }
    return this.kovaApiClient.handleNonOkReponse<Response>(response);
  }

  async deleteCustomPage(apiToken: AdminToken, id: ICustomPage["id"]): Promise<Response> {
    const response = await this.kovaApiClient.delete(`Links/${id}`, apiToken)

    if (response.ok)
    {
      return response;
    }
    return this.kovaApiClient.handleNonOkReponse<Response>(response);
  }

  async editCustomPage(apiToken: AdminToken, id: ICustomPage["id"], customPage: ICustomPage): Promise<Response> {
    const body = JSON.stringify(customPage);
    const response = await this.kovaApiClient.save(`Links/${id}`, apiToken, body, "PATCH", 'application/json')

    if (response.ok)
    {
      return response;
    }
    return this.kovaApiClient.handleNonOkReponse<Response>(response);
  }

  async fetchConfigurations(apiToken: AdminToken):Promise<IConfiguration[]> {
    const response = await this.kovaApiClient.get(`settings`, apiToken)

    if (response.ok)
    {
      let values = await response.json() as IConfiguration[];
      return values;
    }
    return this.kovaApiClient.handleNonOkReponse<IConfiguration[]>(response);
  }

  async updateConfiguration(apiToken: AdminToken, id: IConfiguration["id"], configuration: IConfiguration):Promise<Response> {
    const body = JSON.stringify(configuration);
    const response = await this.kovaApiClient.save(`UpdateSetting/${id}`, apiToken, body, "PATCH", 'application/json')

    if (response.ok)
    {
      return response;
    }
    return this.kovaApiClient.handleNonOkReponse<Response>(response);
  }

  async fetchColorSchemes(apiToken: AdminToken):Promise<IColorScheme[]> {
    const response = await this.kovaApiClient.get(`colorSchemes`, apiToken)

    if (response.ok)
    {
      let values = await response.json() as IColorScheme[];
      return values;
    }
    return this.kovaApiClient.handleNonOkReponse<IColorScheme[]>(response);
  }

  async createColorScheme(apiToken: AdminToken, colorScheme: IColorScheme):Promise<Response> {
    const body = JSON.stringify(colorScheme);
    const response = await this.kovaApiClient.save(`ColorSchemes`, apiToken, body, "POST", 'application/json')

    if (response.ok)
    {
      return response;
    }
    return this.kovaApiClient.handleNonOkReponse<Response>(response);
  }

  async updateColorScheme(apiToken: AdminToken, id: IColorScheme["id"], colorScheme: IColorScheme):Promise<Response> {
    const body = JSON.stringify(colorScheme);
    const response = await this.kovaApiClient.save(`ColorSchemes/${id}`, apiToken, body, "PATCH", 'application/json')

    if (response.ok)
    {
      return response;
    }
    return this.kovaApiClient.handleNonOkReponse<Response>(response);
  }

  async deleteColorScheme(apiToken: AdminToken, id: IColorScheme["id"]):Promise<Response> {
    const response = await this.kovaApiClient.delete(`ColorSchemes/${id}`, apiToken)

    if (response.ok)
    {
      return response;
    }
    return this.kovaApiClient.handleNonOkReponse<Response>(response);
  }

  async fetchNotifications(apiToken: AdminToken):Promise<INotification[]> {
    const response = await this.kovaApiClient.get(`Messages`, apiToken)

    if (response.ok)
    {
      let values  = await response.json() as INotification[];
      return values;
    }
    return this.kovaApiClient.handleNonOkReponse<INotification[]>(response);
  }

  async addNotification(apiToken: AdminToken, notification: { header: string; date: string; text: string; type: string; hideDate: boolean; }):Promise<Response> {
    const body = JSON.stringify(notification);
    const response = await this.kovaApiClient.save(`Messages`, apiToken, body, "POST", 'application/json')

    if (response.ok)
    {
      return response;
    }
    return this.kovaApiClient.handleNonOkReponse<Response>(response);
  }

  async deleteNotification(apiToken: AdminToken, id: INotification["id"]):Promise<Response> {
    const response = await this.kovaApiClient.delete(`Messages/${id}`, apiToken)

    if (response.ok)
    {
      return response;
    }
    return this.kovaApiClient.handleNonOkReponse<Response>(response);
  }

  async editNotification(apiToken: AdminToken, id: INotification["id"], notification: { header: string; date: string; text: string; hideDate: boolean; }):Promise<Response> {
    const body = JSON.stringify(notification);
    const response = await this.kovaApiClient.save(`Messages/${id}`, apiToken, body, "PATCH", 'application/json')

    if (response.ok)
    {
      return response;
    }
    return this.kovaApiClient.handleNonOkReponse<Response>(response);
  }

  async getKovaCustomViews():Promise<KovaCustomView[]> {

    const response = await this.kovaApiClient.get(`CustomViews`);

    if (response.ok)
    {
      return await response.json() as KovaCustomView[];
    }

    return this.kovaApiClient.handleNonOkReponse<KovaCustomView[]>(response);
  }

  async getCustomViewConfigurations(type?: string):Promise<CustomViewConfiguration[]> {

    let typeParam = type ? '?type=' + type : "";

    const response = await this.kovaApiClient.get(`CustomViewConfigurations` + typeParam);

    if (response.ok)
    {
      return await response.json() as CustomViewConfiguration[];
    }

    return this.kovaApiClient.handleNonOkReponse<CustomViewConfiguration[]>(response);
  }

  async createCustomViewConfiguration(apiToken: AdminToken, configuration: Partial<CustomViewConfiguration>):Promise<string> {

    let bodyJson = JSON.stringify(configuration);
    const response = await this.kovaApiClient.save(`CustomViewConfigurations`, apiToken, bodyJson);

    if (response.ok)
    {
      return await response.text();
    }

    return this.kovaApiClient.handleNonOkReponse<string>(response);
  }

  async updateCustomViewConfiguration(apiToken: AdminToken, configuration: Partial<CustomViewConfiguration>):Promise<Response> {

    let bodyJson = JSON.stringify(configuration);
    const response = await this.kovaApiClient.save(`CustomViewConfigurations/${configuration.id}`, apiToken, bodyJson, "PATCH");

    if (response.ok)
    {
      return response;
    }

    return this.kovaApiClient.handleNonOkReponse<Response>(response);
  }
  async deleteCustomViewConfiguration(apiToken: AdminToken, id: string):Promise<Response> {

    const response = await this.kovaApiClient.delete(`CustomViewConfigurations/${id}`, apiToken);

    if (response.ok)
    {
      return response;
    }

    return this.kovaApiClient.handleNonOkReponse<Response>(response);
  }



}

export default AdminApiClient;
