import styles from './CommunityInfoForm.module.css';
import {Box, Button} from "@mui/material";
import useToken from "../../hooks/useToken";
import {Add, Close} from "@mui/icons-material";
import {IKovaCommunity, IKovaModel} from "../../api/generalApiClient";
import { ICommunityInfoConfig, ICommunityInfo } from '../../api/manifest';
import useGeneralApiClient from '../../data/generalApiSource';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Dropdown, MultiSelectDropdown, TextInput } from '@bimaire/react-lib';

export default function CommunityInfoForm(props: {
  onClose: () => void,
  onSave: () => void,
  selectedCommunityInfo: ICommunityInfoConfig | undefined,
  communities: IKovaCommunity[] | undefined,
  models: IKovaModel[] | undefined
}) {
  const {onClose, onSave, selectedCommunityInfo, communities, models} = props;
  const {adminToken} = useToken();
  if (adminToken === null) throw "Not logged in";

  const { handleSubmit, control, formState: { errors } } = useForm<ICommunityInfoConfig>({values: selectedCommunityInfo});
  const onSubmit: SubmitHandler<ICommunityInfoConfig> = data => saveCommunityInfo(data);

  const modelItems = models?.map((m) => ({value: m.ModelRID, label: m.Name + " - " + m.ModelID})) ?? [];
  const communityItems = communities?.map((c) => ({value: c.BUnitRID, label: c.Name + " - " + c.CommunityID})) ?? [];
  const statuses = [{value: "Active", label: "Active"}, {value: "Testing", label: "Testing"}, {value: "Inactive", label: "Inactive"}]

  const generalApiSource = useGeneralApiClient();
  
  const saveCommunityInfo = (config: ICommunityInfoConfig) => {

    const community = communities?.find(c => c.BUnitRID == config.bunitRID);
    const model = models?.find(m => m.ModelRID == config.modelRID);

    if (!community || !model) {
      alert('Community and Model need to be selected!');
      return;
    }

    try {

      const defaultName = (community.Name ?? '') + ' - ' + model.Name ?? '';

      if (selectedCommunityInfo) {

        let communityInfo : ICommunityInfo = {
          id: config.id,
          theme: config.theme,
          status: config.status,
          name: config.name ?? defaultName,
          filename: config.filename,
          skuFilename: config.skuFilename ?? config.id + "_sku.json",
          ociFilename: config.ociFilename ?? config.id + "_oci.json",
          fetchedAt: config.fetchedAt,
          optSels: JSON.parse(config.optSels),
          content: config.content ? JSON.parse(config.content) : null,
          optSelChain: config.optSelChain ? config.optSelChain.replaceAll(',', '=-').replaceAll(' ', '') : '',
          modelAvails: config.modelAvails ?? [],
          dataSources: config.dataSources
        };

        generalApiSource.updateOptionConfig(adminToken, communityInfo, community.CommunityID, model.ModelID).then(() => {
          onSave();
        })
      }
      else {
        const infoId = config.bunitRID + "_0_" + config.modelRID;

        let communityInfo : ICommunityInfo = {
          id: infoId,
          theme: config.theme,
          status: config.status,
          name: defaultName,
          filename: infoId + '.json',
          skuFilename: infoId + "_sku.json",
          ociFilename: infoId + "_oci.json",
          fetchedAt: "0",
          optSels: JSON.parse(config.optSels),
          content: config.content ? JSON.parse(config.content) : null,
          optSelChain: config.optSelChain ? config.optSelChain.replaceAll(',', '=-').replaceAll(' ', '') : '',
          modelAvails: config.modelAvails ?? [],
          dataSources: config.dataSources
        };

        generalApiSource.updateOptionConfig(adminToken, communityInfo, community.CommunityID, model.ModelID).then(() => {
          onSave();
        })
      }
    } catch (err) {
      alert(err);
    }
  };


  return (
    <div className={styles.container}>
      <h1>{selectedCommunityInfo ? 'Update' : 'Add'} Community Info Config</h1>
      <form onSubmit={handleSubmit(onSubmit)} noValidate
        style={{display: 'flex', flexFlow: 'row wrap', gap: 16}}>

        <Box sx={{display: 'flex', flexFlow: 'column wrap', gap: 2, width: '500px'}}>
          <Dropdown control={control} name="bunitRID" label="Community" selectionItems={communityItems} disabled={selectedCommunityInfo !== undefined} />
          <Dropdown control={control} name="modelRID" label="Model" selectionItems={modelItems} disabled={selectedCommunityInfo !== undefined}/>
          <MultiSelectDropdown control={control} name="modelAvails" label="Available for Models" selectionItems={modelItems} />
          <Dropdown control={control} name="status" label="Status" selectionItems={statuses} rules={{ required: true }} />
          <TextInput control={control} name="theme" label="Theme Name" rules={{ required: true }} />
          <Box sx={{flex: 2}}></Box>
        </Box>
        <Box sx={{display: 'flex', flexFlow: 'column wrap', gap: 2, width: '500px'}}>
          <TextInput control={control} name="optSels" label="Option Selections" multiline rules={{ required: true }} />
          <TextInput control={control} name="optSelChain" label="Story Mode Options" helperText='OptSelID1,OptSelID2' />
          <TextInput control={control} name="content" label="Content" multiline />
        </Box>
        
        <Button
          type="submit"
          startIcon={<Add />}
          sx={{ bgcolor: 'primary.light' }}
          variant="contained"
        >
          SUBMIT
        </Button>
        <Button
          onClick={() => onClose()}
          startIcon={<Close />}
          sx={{ bgcolor: 'primary.light', marginLeft:'20px' }}
          variant="contained"
        >
          CLOSE
        </Button>
      </form>
    </div>
  );
}
