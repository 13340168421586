import {Box, Card, CardHeader} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import styles from './Branding.module.css';
import useToken from "../../hooks/useToken";
import {IFile} from "../../api/adminApiClient";
import useAdminApiClient from "../../data/adminApiSource";

export default function ModifyCommunityImages(props: {fileKey: string, title: string, sourceFileInfos:IFile[] | undefined, onFileLoad: (id:string) => void}) {
  const {adminToken} = useToken();
  const adminApiSource = useAdminApiClient();
  const {fileKey, title, sourceFileInfos, onFileLoad} = props;
  const [url, setUrl] = useState<string | null>(null);
  const [communityRID, setCommunityRID] = useState<number>(0);
  const [file, setFile] = useState<File | null>(null);
  const communityInputRef = useRef<HTMLInputElement>(null);
  const uploadInputRef = useRef<HTMLInputElement>(null);


  function handleFileLoad(id: string) {
    setFile(null);
    if (communityInputRef.current != null) {
      communityInputRef.current.value = '0';
    }
    if (uploadInputRef.current != null) {
      uploadInputRef.current.value = '';
    }
    onFileLoad(id);
  }

  function handleCommunityChange(event: React.ChangeEvent<HTMLInputElement>) {
    const communityRID = event.target.value;
    if (communityRID) {
      setCommunityRID(Number(communityRID));
    }
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const files = event.target.files;
    if (files && files.length > 0) {
      setFile(files[0]);
    }
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (file && sourceFileInfos) {
      if(file.size > 20000000) {
        alert('Too large file (max 20MB)');
        return;
      }

      const formData = new FormData();
      formData.append('data', file as Blob);
      formData.append('fileName', file.name);
      formData.append('fileType', file.type);

      const fileInfo = sourceFileInfos.find(file => file.communityRID == communityRID);

      if (!fileInfo) {
        formData.append('communityRID', communityRID.toString());
        formData.append('fileKey', fileKey);

        adminApiSource.uploadNewFile(adminToken!, formData).then(response => {
          const loc = response.headers.get('location');
          let id = undefined;
          if (loc) {
            id = loc.split('/').pop();
          }
          if(id) {
            handleFileLoad(id);
          }
        })
      } else {
        adminApiSource.updateFile(adminToken!, fileInfo.id!, formData).then(()=> {
          handleFileLoad(fileInfo.id!);
        })
      }
    }
  }

  function deleteRow(file:IFile) {
    adminApiSource.deleteFile(adminToken!, file.id).then(() => {
      onFileLoad(file.id!);
    })
  }

  useEffect(() => {
    try {
      const fileInfo = sourceFileInfos?.find(file => file.communityRID == communityRID);
      if (fileInfo) {
        setUrl(fileInfo.s3Path);
      }
    } catch (err) {
      alert('Could not get file data!');
    }
  }, [sourceFileInfos]);

  return (
    <>
      <Card>
        <CardHeader title={title} />
        <form encType="multipart/form-data" onSubmit={(e) => handleSubmit(e)}>
          <Box ml={5} borderBottom={1} paddingBottom={4}>
            <h3>{`Current ${title}s:`}</h3>
            {!sourceFileInfos && (
              <div>{`${title} not found`}</div>
            )}
            {sourceFileInfos && (
              <div className={styles.communityLogoTable}>
                <header>
                  <div>Community RID</div>
                  <div>File Name</div>
                  <div>File Type</div>
                  <div>View</div>
                  <div>Delete</div>
                </header>
                {sourceFileInfos.map((file) => (
                  <div className={styles.communityLogo} key={`file_${file.id}_${file.communityRID}`}>
                    <div>{file.communityRID}</div>
                    <div>{file.fileName}</div>
                    <div>{file.fileType}</div>
                    <div><img className={styles.imagePreview} src={file.s3Path} /></div>
                    <div><button type={"button"} onClick={() => deleteRow(file)}>Delete</button></div>
                  </div>
                ))}
              </div>
            )}
          </Box>
          <Box ml={5} paddingBottom={4}>
            <h3>{`New ${title}:`}</h3>
            {file && (
              <><img alt={`New ${title}`} className={styles.companylogo} src={URL.createObjectURL(file)} /><br /></>
            )}
            <input accept="image/*" type="file" onChange={(e) => handleChange(e)} ref={uploadInputRef} />
            {file && (
              <>
                <p>Community:&nbsp;
                <input type="number" onChange={(e) => handleCommunityChange(e)} ref={communityInputRef} />
                <button type="submit">Submit</button>
                </p>
              </>
            )}
          </Box>
        </form>
      </Card>
    </>
  );
}
