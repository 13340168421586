import {Chip, List, ListItemButton, ListItemText} from "@mui/material";
import React from "react";
import GlobalConfig from "../../utils/GlobalConfig";
import useToken from "../../hooks/useToken";
import {CommunityLotData} from "../../api/generalApiClient";
import apiClient from "../../api/apiClient";
import adminApiClient from "../../api/adminApiClient";
import useGeneralApiClient from "../../data/generalApiSource";
import useAdminApiClient from "../../data/adminApiSource";
import {useMsal} from "@azure/msal-react";

export function setStatusColor(status: string) {
  switch(status) {
    case 'Available':
      return 'warning.main';
    case 'Sold':
      return 'success.main';
    default:
      return 'primary.main';
  }
}
export function LotsList(props: { lots: CommunityLotData[] | undefined }) {
  const {adminToken} = useToken();
  const {lots} = props;
  const adminApiSource = useAdminApiClient();

  return (
    <List>
      {lots?.map((lot) => (
        <ListItemButton
          key={lot.LotRID}
          onClick={async () => {

            const queryString = `/proxylogin?customerrid=${lot.Customer.CustomerRID}`;

            // Safari does not allow window.open in async calls, setTimeout runs on main thread
            setTimeout(() => {
              window.open(`${GlobalConfig.instance().CUSTOMER_PORTAL_URL}${queryString}`);
            });
          }}
          sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.25)" }}
        >
          <ListItemText primary={`Lot #${lot.Lot.LotNumber}`} secondary={`${lot.Customer.Name}`} />
          <Chip
            label={lot.Lot.Status}
            sx={{ borderColor: setStatusColor(lot.Lot.Status), color: setStatusColor(lot.Lot.Status) }}
            variant="outlined"
          />
        </ListItemButton>
      ))}
    </List>
  );
}
