import React, {useEffect, useState} from "react";
import styles from "../Branding.module.css";
import useToken from "../../../hooks/useToken";
import {IConfiguration} from "../../../api/adminApiClient";
import useAdminApiClient from "../../../data/adminApiSource";

export default function ConfigurationGrid() {
  const {adminToken} = useToken();
  const adminApiSource = useAdminApiClient();
  const [isLoadingIndicatorActive, setIsLoadingIndicatorActive] = useState(true);
  const [configurations, setConfigurations] = useState<IConfiguration[]>();

  function onEnabledChange(event: React.ChangeEvent<HTMLInputElement>, configuration: IConfiguration) {
    try {
      const checked = event.currentTarget.checked;
      configuration.value = checked ? "true" : "false";

      setIsLoadingIndicatorActive(true);
      adminApiSource.updateConfiguration(adminToken!, configuration.id, configuration).then(configs => {

        setIsLoadingIndicatorActive(false);
      });

    } catch (err) {
      alert('Add failed!');
    }
  }

  function onTextChanged(event: React.ChangeEvent<HTMLInputElement>, configuration: IConfiguration) {
    try {

      if(configuration.type === "int") {
        let newValue = -1;
        if (event.currentTarget.value.length > 0) {
          newValue = parseInt(event.currentTarget.value);
        } else {
          newValue = 0;
        }
        if (!isNaN(newValue) && newValue >= 0) {
          configuration.value = newValue.toString();

          let updateConfigurations = [...configurations as IConfiguration[]];
          setConfigurations(updateConfigurations);
        }
      }
      else{
        configuration.value = event.currentTarget.value;
        let updateConfigurations = [...configurations as IConfiguration[]];
        setConfigurations(updateConfigurations);
      }

    } catch (err) {
      alert('Add failed!');
    }
  }

  function onSelectionChanged(event: React.ChangeEvent<HTMLSelectElement>, configuration: IConfiguration) {
      configuration.value = event.currentTarget.value;

      setIsLoadingIndicatorActive(true);
      adminApiSource.updateConfiguration(adminToken!, configuration.id, configuration).then(configs => {
        setIsLoadingIndicatorActive(false);
      });
  }

  function onValueChange(event: React.ChangeEvent<HTMLInputElement>, configuration: IConfiguration) {
    try {
      if(configuration.type === "int") {
        let newValue = parseInt(event.currentTarget.value);
        if (!Number.isNaN(newValue)) {
          setIsLoadingIndicatorActive(true);
          adminApiSource.updateConfiguration(adminToken!, configuration.id, configuration).then(configs => {

            setIsLoadingIndicatorActive(false);
          });
        } else {
          alert('Invalid number');
        }
      }
      else{
        setIsLoadingIndicatorActive(true);
        adminApiSource.updateConfiguration(adminToken!, configuration.id, configuration).then(configs => {

          setIsLoadingIndicatorActive(false);
        });
      }
    } catch (err) {
      alert('Add failed!');
    }
  }

  function loadConfiguration() {
    try {
      setIsLoadingIndicatorActive(true);
      adminApiSource.fetchConfigurations(adminToken!).then(configs => {
        setConfigurations(configs);
        setIsLoadingIndicatorActive(false);
      });
    } catch (err) {
      alert('Edit failed!');
    }
  }

  useEffect(() => {
    try {
      loadConfiguration();
    } catch (err) {
      alert('Could not get configuration!');
    }
  }, []);

  return (
        <div className={styles.customPagesTable}>
          <header>
            <div>Name</div>
            <div>BUnit</div>
            <div>Status</div>
          </header>
          {
            !configurations ? (<div>Loading Pages...</div>) :
              configurations.length > 0 ?
                configurations.map( config => {
                  return (
                    <div className={styles.linkItem} key={config.id}>
                      <div>{config.settingName}</div>
                      <div>{config.bunitRid}</div>
                      <div>
                        {config.type == "boolean" && (
                        <label htmlFor={config.id}>
                          Enabled
                          <input
                            id={config.id}
                            name="enabled"
                            onChange={(e) => onEnabledChange(e, config)}
                            type="checkbox"
                            checked={config.value === "true"}
                          />
                        </label>)}
                        {(config.type == "int" || config.type == "text") && (
                            <label htmlFor={config.id}>
                              Value
                              <input
                                  style={{width: "500px"}}
                                  id={config.id}
                                  name="value"
                                  onBlur={(e) => onValueChange(e, config)}
                                  value={config.value}
                                  onChange={(e)  => onTextChanged(e, config)}
                                  type="text"
                              />
                            </label>)}
                        {(config.type == "selection") && (
                            <label htmlFor={config.id}>
                              Value
                              <select
                                  style={{width: "500px"}}
                                  id={config.id}
                                  name="value"
                                  onChange={(e)  => onSelectionChanged(e, config)}
                              >
                                { config.availableValues.map((availValue) => <option value={availValue} key={availValue} selected={availValue === config.value}>{availValue}</option>) }
                              </select>
                            </label>)}
                      </div>
                    </div>
                  )
                })
                : (
                  <div>No Configurations Found.</div>
                )
          }
        </div>
  );
}
