import {Backdrop, Box, Card, CardHeader} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import styles from './Branding.module.css';
import LoadingIndicator from "../../components/LoadingIndicator";
import useToken from "../../hooks/useToken";
import useAdminApiClient from "../../data/adminApiSource";
import {IFile} from "../../api/adminApiClient";

export default function ModifySingleFile(props: {fileKey: string, filetype: string, title: string, sourceFileInfo:IFile | undefined, onFileLoad: (id:string) => void, additionalViewComponent?:React.ReactNode}) {
  const {adminToken} = useToken();
  const adminApiSource = useAdminApiClient();
  const {fileKey, filetype, title, sourceFileInfo, onFileLoad, additionalViewComponent} = props;
  const [isLoadingIndicatorActive, setIsLoadingIndicatorActive] = useState(false);
  const [url, setUrl] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const uploadInputRef = useRef<HTMLInputElement>(null);

  function handleFileLoad(id: string) {
    setFile(null);
    if (uploadInputRef.current != null) {
      uploadInputRef.current.value = '';
    }
    onFileLoad(id);
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const files = event.target.files;
    if (files && files.length > 0) {
      setFile(files[0]);
    }
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (file) {
      if(file.size > 20000000) {
        alert('Too large file (max 20MB)');
        return;
      }

      const formData = new FormData();
      formData.append('data', file as Blob);
      formData.append('fileName', file.name);
      formData.append('fileType', file.type);

      if (!sourceFileInfo) {
        formData.append('communityRID', '0');
        formData.append('fileKey', fileKey);

        adminApiSource.uploadNewFile(adminToken!, formData).then(response => {
          const loc = response.headers.get('location');
          let id = null;
          if (loc) {
            id = loc.split('/').pop();
          }
          if(id) {
            handleFileLoad(id);
          }})
      } else {
        adminApiSource.updateFile(adminToken!, sourceFileInfo.id!, formData).then(()=> {
          handleFileLoad(sourceFileInfo.id!);
        })
      }
    }
  }

  useEffect(() => {
    try {
      const fileInfo = sourceFileInfo!;
      if (fileInfo) {
          setUrl(fileInfo.s3Path);
      }
    } catch (err) {
      alert('Could not get file data!');
    }
  }, [sourceFileInfo]);

  return (
    <>
      <Card>
        <CardHeader title={title} />
        <form encType="multipart/form-data" onSubmit={(e) => handleSubmit(e)}>
          <Box ml={5} borderBottom={1} paddingBottom={4}>
            <h3>{`Current ${title}:`}</h3>
            {!url && (
              <div>{`${title} not found`}</div>
            )}
            {url && (
              <>
                {additionalViewComponent && (
                  additionalViewComponent
                )}
              {filetype === 'json' ?
                (<a className={styles.dictionary} href={url} target='_blank'>View</a>) :
                (<img alt={`Current ${title}`} className={styles.companylogo} src={url}/>)
              }
              </>
            )}
          </Box>
          <Box ml={5} paddingBottom={4}>
            <h3>{`New ${title}:`}</h3>
            {file && (
              <>
              {filetype === 'json' ?
                (<><a className={styles.dictionary} href={URL.createObjectURL(file)} target='_blank'>View</a><br /></>) :
                (<><img alt={`New ${title}`} className={styles.companylogo} src={URL.createObjectURL(file)}/><br/></>)
              }
              </>
            )}
            <input accept={ filetype ==='json' ? 'application/json' : 'image/*'} type="file" onChange={(e) => handleChange(e)} ref={uploadInputRef} />
            {file && (
              <button type="submit">Submit</button>
            )}
          </Box>
        </form>
      </Card>
      {isLoadingIndicatorActive && (
        <Backdrop
          open={isLoadingIndicatorActive}
          sx={{ bgcolor: 'rgba(0, 0, 0, 0.6)', color: '#fff', position: 'absolute' }}
        >
          <LoadingIndicator
            color="#fff"
            isLoading={url === null}
            message={`Loading ${title}...`}
          />
        </Backdrop>
      )}
    </>
  );
}
