import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {Fab} from "@mui/material";
import React from "react";
import {Edit} from "@mui/icons-material";
import {INotification} from "../../api/adminApiClient";

export function MessagesGrid(props: { messages: INotification[] | undefined, openEdit: (message:INotification) => void  }) {
  const {messages, openEdit} = props;

  const columns: GridColDef[] = [
    {field: 'id', flex: 0, headerName: 'Id'},
    {field: 'date', flex: 1, headerName: 'Date'},
    {field: 'hideDate', flex: 1, headerName: 'Hide date'},
    {field: 'header', flex: 1, headerName: 'Header'},
    {field: 'text', flex: 2, headerName: 'Text'},
    {field: 'type', flex: 1, headerName: 'Type'},
    {
      field: 'Open',
      flex: 0,
      headerName: 'Open',
      renderCell: (params: any) => (
        <Fab color="primary" size={"small"} aria-label="go" sx={{
          ":hover":{
            backgroundColor:"primary.light"
          }
        }}>
          <Edit />
        </Fab>
      )
    }
  ];

  return (
    <>
      {messages &&
        (
          <DataGrid
            columns={columns}
            hideFooter
            onCellClick={(params => {
              if (params.colDef.field == "Open") {
                openEdit(params.row);
              }
            })}
            getRowId={(row:INotification) => row.id!}
            rows={messages}
            sx={{ border: 'none' }}
            disableRowSelectionOnClick={true}
          />
        )
      }
    </>
  );
}
