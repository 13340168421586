import React from 'react';
import styles from './CustomPageForm.module.css';
import {Button} from "@mui/material";
import useToken from "../../../hooks/useToken";
import useInput from '../../../hooks/useInput';
import {Add, Close} from "@mui/icons-material";
import {ICustomPage} from "../../../api/adminApiClient";
import useAdminApiClient from "../../../data/adminApiSource";
import {IKovaCommunity} from "../../../api/generalApiClient";

export default function CustomPageForm(props: {
  onClose: () => void,
  onSave: () => void,
  selectedPage: ICustomPage | undefined,
  communities: IKovaCommunity[] | undefined
}) {
  const {onClose, onSave, selectedPage, communities} = props;
  const {adminToken} = useToken();
  const adminApiSource = useAdminApiClient();
  const {onChange: onNameChange, value: pageName} = useInput(selectedPage ? selectedPage.text : '');
  const {onChange: onUrlChange, value: url} = useInput(selectedPage ? selectedPage.url : '');
  const {onChange: onCommunityRIDChange, value: communityRID} = useInput(selectedPage ? selectedPage.communityRID : 0);
  const {onChange: onEnabledChange, value: enabled} = useInput((selectedPage ? selectedPage.enabled === 1 : false).toString());

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!pageName || !url) {
      alert('All fields need to be filled!');
      return;
    }

    try {
      if (selectedPage) {
        selectedPage.linkKey = pageName;
        selectedPage.text = pageName;
        selectedPage.enabled = enabled === 'true' ? 1 : 0;
        selectedPage.url = url;
        selectedPage.communityRID = communityRID;

        await adminApiSource.editCustomPage(adminToken!, selectedPage.id, selectedPage).then(() => {
          onSave();
        })
      }
      else {
        let customPage : ICustomPage = {
          id: undefined,
          linkKey: pageName,
          text: pageName,
          enabled: Boolean(enabled) ? 1 : 0,
          url: url,
          communityRID: communityRID
        };

        adminApiSource.addCustomPage(adminToken!, customPage).then(() => {
          onSave();
        })
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <div className={styles.container}>
      <h1>Add Custom Page</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor="pageName">
          Page Name
          <input
            id="pageName"
            name="pageName"
            onChange={onNameChange}
            placeholder="New Custom Page"
            type="text"
            required={true}
            value={pageName}
          />
        </label>
        <label htmlFor="url">
          URL
          <input
            id="url"
            name="url"
            onChange={onUrlChange}
            placeholder="https://"
            type="text"
            required={true}
            value={url}
          />
        </label>
        <label htmlFor="community">
          Community
          <div>
          <select
              id="communityRID"
              name="communityRID"
              onChange={onCommunityRIDChange}
              placeholder="0"
              required={true}
              value={communityRID}
              >
              {
                communities && communities.map((c) =>
                <option value={c.CommunityRID} key={c.CommunityRID}>{c.Name}</option>
              )}
          </select>
          </div>
        </label>
        <label htmlFor="enabled">
          Enabled
          <input
            id="enabled"
            name="enabled"
            onChange={onEnabledChange}
            type="checkbox"
            checked={enabled === 'true'}
          />
        </label>
        <Button
          type="submit"
          startIcon={<Add />}
          sx={{ bgcolor: 'primary.light' }}
          variant="contained"
        >
          SUBMIT
        </Button>
        <Button
          onClick={() => onClose()}
          startIcon={<Close />}
          sx={{ bgcolor: 'primary.light', marginLeft:'20px' }}
          variant="contained"
        >
          CLOSE
        </Button>
      </form>
    </div>
  );
}
